import Repository from "./repository";
const createUser = "/users";
const ResubscribeUser = "/payment/resubscribe";
const subscribeUser = "/payment/subscribe";
const NewSubscription = "/payment/generate-link";
const paypalRefund = "/payment/paypal-refund";
const stripeRefund = "/payment/stripe-refund";
const verifyCoupon = "/payment/verify-coupon";

export default {
  add(payload) {
    return Repository.post(`${createUser}`, payload);
  },
  updateDriver(userID, payload) {
    return Repository.put(`${createUser}/${userID}`, payload);
  },
  CouponVerification(payload) {
    return Repository.post(`${verifyCoupon}`, payload);
  },
  createSubscription(payload) {
    console.log(payload, "payload");
    return Repository.post(`${subscribeUser}`, payload);
  },
  createNewSubscription(payload) {
    return Repository.post(`${NewSubscription}`, payload);
  },
  getSubscriptionStatus(subscriptionID) {
    console.log(subscriptionID, "subscriptionID");
    return Repository.get(`/payment/getSubscription/${subscriptionID}`);
  },

  unSubscribe(subscriptionID) {
    console.log(subscriptionID, "subscriptionID>>>>>>>>>>");
    return Repository.get(`/payment/unsubscribe/${subscriptionID}`);
  },
  updateSubscription(payload) {
    console.log(payload, "payload");
    return Repository.post(`/payment/upgradeSubscription`, payload);
  },

  reSubscribe(payload) {
    // console.log(payload, "payload");
    return Repository.post(`${ResubscribeUser}`, payload);
  },
  paymentRefund(payload) {
    // console.log("Payload in User", payload);
    return Repository.post(`${ResubscribeUser}`, payload);
  },
  paymentRefund(payload) {
    return Repository.post(
      `${payload.paymentMethod === "Stripe" ? stripeRefund : paypalRefund}`,
      payload.paymentMethod === "Stripe"
        ? {
            chargeId: payload.paymentID,
            amount: payload.totalAmount,
            orderId: payload.id,
          }
        : {
            transactionId: payload.paymentID,
            amount: payload.totalAmount,
            orderId: payload.id,
          }
    );
  },
};
