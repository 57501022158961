import React from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Button,
} from "reactstrap";

const ClientSettingTable = ({
  data,
  addToggle,
  editToggle,
  deleteToggle,
  setEditData,
  setIndex,
}) => {
  const history = useHistory();

  return (
    <>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="d-lg-flex  d-sm-block justify-content-between">
              <div className="d-flex align-items-center">
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    history.push("/admin/client-setting-menu");
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </Button>

                <h3 className=" pt-2 ">Tip Settings</h3>
              </div>

              <Button
                color="primary"
                onClick={addToggle}
                disabled={data?.length > 4}
              >
                Add
              </Button>
            </CardHeader>

            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Tip</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((el, id) => {
                    return (
                      <tr key={id}>
                        <td> {el} %</td>

                        <td>
                          <Button
                            className="btn-sm"
                            color="primary"
                            onClick={() => {
                              setIndex(id);
                              setEditData(el);
                              editToggle();
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            className="btn-sm"
                            color="danger"
                            onClick={() => {
                              setEditData(el);
                              deleteToggle();
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-1"></CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default ClientSettingTable;
