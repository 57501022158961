/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
// reactstrap components
import { Container } from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import CatalogTable from "components/Catalog/CatalogTable";
import AddCatalogModal from "components/Modals/AddCatalogModal";
import DeleteCatalogModal from "components/Modals/DeleteCatalogModal";
import EditCatalogModal from "components/Modals/EditCatalogModal";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogsByID } from "store/actions/catalogAction";

const Catalog = () => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState("");
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { catalogs, loading } = useSelector((state) => state.catalog);

  const dispatch = useDispatch();

  const addToggle = () => {
    setAddModal(!addModal);
  };
  const editToggle = () => {
    setEditModal(!editModal);
  };
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };
  useEffect(() => {
    dispatch(getCatalogsByID(user?.restaurantID));
  }, []);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <CatalogTable
          // data={catalogs}
          addToggle={addToggle}
          editToggle={editToggle}
          deleteToggle={deleteToggle}
          setEditData={setEditData}
          setId={setId}
        />

        <AddCatalogModal addModal={addModal} addToggle={addToggle} />

        <EditCatalogModal
          editModal={editModal}
          editToggle={editToggle}
          editData={editData}
          id={id}
        />
        <DeleteCatalogModal
          deleteModal={deleteModal}
          deleteToggle={deleteToggle}
          title={"Catalog"}
          id={id}
        />
      </Container>
    </>
  );
};

export default Catalog;
