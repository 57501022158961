import PopUp from "components/PopUp";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Input, Row, Spinner, Table } from "reactstrap";
import { updateProfilePicture } from "store/actions/settingAction";
import defaultLogo from "../../assets/img/Mpink.png";

const CustomizationTable = ({
  data,
  logo,
  addToggle,
  editToggle,
  deleteToggle,
  setEditData,
  setIndex,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { user, userPermissions } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.setting);
  const [colorSelect, setColorSelect] = useState(false);
  const [customerSelect, setCustomerSelect] = useState(false);
  const [adminColor, setadminColor] = useState("");
  const [customerColor, setCustomerColor] = useState("");

  useEffect(() => {
    setadminColor(user?.adminColor || "#d70365");
    setCustomerColor(user?.customerColor || "#d70365");
  }, [user]);
  const customizationPermissions = userPermissions?.customization;

  const togglePopup = () => {
    setColorSelect(!colorSelect);
  };
  const togglePopupCustomer = () => {
    setCustomerSelect(!customerSelect);
  };
  const [img, setImages] = useState();
  const handleUpdateLogo = (e) => {
    let item2 = {
      id: user?.restaurantID,
      img: e.target.files[0],
    };
    setImages(e.target.files[0]);
    let MAX_FILE_SIZE = 1024 * 1024;
    const fileSizeKiloBytes = e.target.files[0].size;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.warn("File size is greater than 1MB");
    } else {
      dispatch(updateProfilePicture(item2));
    }
  };
  return (
    <>
      <Row className="pb-5">
        <div className="col">
          {user?.type == "kitchen-admin" ? (
            <>
              {customizationPermissions?.get ? (
                <div>
                  <div className="d-lg-flex my-2 d-sm-block justify-content-between">
                    <div className="d-flex align-items-center  px-3">
                      <h3 className=" pt-2">Customization</h3>
                    </div>
                    {customizationPermissions?.update ? (
                      <Button color="primary" size="sm" onClick={addToggle}>
                        Add
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>

                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((el, id) => {
                          return (
                            <tr key={id}>
                              <th scope="row">
                                <img
                                  alt="Banner "
                                  src={el}
                                  height={50}
                                  width={50}
                                  className="rounded"
                                />
                              </th>
                              <td>
                                {customizationPermissions?.update ? (
                                  <>
                                    {" "}
                                    <Button
                                      className="btn-sm"
                                      color="primary"
                                      onClick={() => {
                                        setIndex(id);
                                        setEditData(el);
                                        editToggle();
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      className="btn-sm"
                                      color="danger"
                                      onClick={() => {
                                        setEditData(el);
                                        deleteToggle();
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className="pb-3 mt-4">
                    <h3 className="px-3">Replace Your logo</h3>
                    <div
                      style={{
                        position: "relative",
                        width: "60px",
                        height: "60px",
                      }}
                    >
                      {loading ? (
                        <Spinner className="ml-3" size="sm" />
                      ) : (
                        <img
                          className="ml-3 img-fluid rounded default-logo"
                          alt="default Logo"
                          src={
                            (img && URL.createObjectURL(img)) ||
                            logo ||
                            defaultLogo
                          }
                          // {logo ? logo : defaultLogo}
                        />
                      )}

                      {customizationPermissions?.update ? (
                        <>
                          {loading ? null : (
                            <i
                              className="fas fa-edit  text-primary bg-white rounded-circle p-1 cursor-pointer"
                              onClick={() => {
                                inputRef.current.click();
                              }}
                              style={{
                                position: "absolute",
                                right: "-23px",
                                bottom: "1px",
                                border: "1px solid #ffff",
                              }}
                            ></i>
                          )}
                          <input
                            type="file"
                            hidden
                            ref={inputRef}
                            onChange={handleUpdateLogo}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="d-flex">
                      <h3 className=" pt-2">Admin Color </h3>
                    </div>
                    {colorSelect && (
                      <PopUp
                        id={"admin"}
                        handleClose={togglePopup}
                        color={adminColor}
                        setColor={setadminColor}
                        customerColor={customerColor}
                        adminColor={adminColor}
                      />
                    )}

                    <Row className="d-flex justify-content-center align-items-center">
                      <Col>
                        <Input type="text" value={adminColor} disabled />
                      </Col>
                      <Col>
                        {customizationPermissions?.update ? (
                          <Button
                            color="primary"
                            onClick={() => setColorSelect(!colorSelect)}
                          >
                            Select
                          </Button>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <h3 className=" pt-5">Customer Site Color</h3>
                    <Row className="d-flex justify-content-center align-items-center">
                      <Col>
                        <Input type="text" value={customerColor} disabled />
                      </Col>
                      <Col>
                        {customizationPermissions?.update ? (
                          <Button
                            color="primary"
                            onClick={() => setCustomerSelect(!customerSelect)}
                          >
                            Select
                          </Button>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    {customerSelect && (
                      <PopUp
                        id={"customer"}
                        handleClose={togglePopupCustomer}
                        color={customerColor}
                        setColor={setCustomerColor}
                        customerColor={customerColor}
                        adminColor={adminColor}
                      />
                    )}
                  </div>
                  {/* <CustomerBG /> */}
                </div>
              ) : (
                <Row>You don't have the permsions to access the page</Row>
              )}
            </>
          ) : (
            <>
              <div className="d-lg-flex my-2 d-sm-block justify-content-between">
                <div className="d-flex align-items-center">
                  <h3 className=" pt-2">Banner</h3>
                </div>
                <Button
                  color="primary"
                  className="mr-4"
                  size="sm"
                  onClick={addToggle}
                >
                  Add Banner
                </Button>
              </div>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((el, id) => {
                      return (
                        <tr key={id}>
                          <th scope="row">
                            <img
                              src={el}
                              style={{ width: "60px", height: "60px" }}
                              alt="Banner "
                              className="rounded"
                            />
                          </th>
                          <td>
                            <Button
                              className="btn-sm"
                              color="primary"
                              onClick={() => {
                                setIndex(id);
                                setEditData(el);
                                editToggle();
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              className="btn-sm"
                              color="danger"
                              onClick={() => {
                                setEditData(el);
                                deleteToggle();
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <div className="pb-3 mt-4">
                <div className="d-flex justify-content-between w-100 mx-3">
                  <h3 className="">Replace Your logo</h3>
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "60px",
                    height: "60px",
                  }}
                >
                  {loading ? (
                    <Spinner className="ml-3" size="sm" />
                  ) : (
                    <img
                      className="ml-3 img-fluid rounded"
                      style={{
                        width: "60px",
                        height: "60px",
                      }}
                      src={
                        (img && URL.createObjectURL(img)) || logo || defaultLogo
                      }
                      alt="Logo"
                      // src={logo.length > 0 ? logo[0] : defaultLogo}
                    />
                  )}

                  <>
                    {loading ? null : (
                      <i
                        className="fas fa-edit  text-primary bg-white rounded-circle p-1 cursor-pointer"
                        onClick={() => {
                          inputRef.current.click();
                        }}
                        style={{
                          position: "absolute",
                          right: "-23px",
                          bottom: "1px",
                          border: "1px solid #ffff",
                        }}
                      ></i>
                    )}
                    <input
                      type="file"
                      hidden
                      ref={inputRef}
                      onChange={handleUpdateLogo}
                    />
                  </>
                </div>
              </div>
              <div className="px-3">
                <div className="d-flex align-items-center">
                  <h3 className=" pt-2">Admin Color</h3>
                  <div
                    className="mx-2"
                    style={{
                      height: "30px",
                      width: "30px",
                      background: `${adminColor}`,
                      borderRadius: "50%",
                    }}
                  ></div>
                </div>
                {colorSelect && (
                  <PopUp
                    id={"admin"}
                    handleClose={togglePopup}
                    color={adminColor}
                    setColor={setadminColor}
                    customerColor={customerColor}
                    adminColor={adminColor}
                  />
                )}

                <Row className="d-flex justify-content-center align-items-center">
                  <Col>
                    <Input type="text" value={adminColor} disabled />
                  </Col>
                  <Col>
                    <Button
                      color="primary"
                      onClick={() => setColorSelect(!colorSelect)}
                    >
                      Select
                    </Button>
                  </Col>
                </Row>
                <div className="d-flex align-items-center">
                  <h3 className=" pt-2">Customer Site Color</h3>
                  <div
                    className="mx-2"
                    style={{
                      height: "30px",
                      width: "30px",
                      background: `${customerColor}`,
                      borderRadius: "50%",
                    }}
                  ></div>
                </div>
                <Row className="d-flex justify-content-center align-items-center">
                  <Col>
                    <Input type="text" value={customerColor} disabled />
                  </Col>
                  <Col>
                    <Button
                      color="primary"
                      onClick={() => setCustomerSelect(!customerSelect)}
                    >
                      Select
                    </Button>
                  </Col>
                </Row>
                {customerSelect && (
                  <PopUp
                    id={"customer"}
                    handleClose={togglePopupCustomer}
                    color={customerColor}
                    setColor={setCustomerColor}
                    customerColor={customerColor}
                    adminColor={adminColor}
                  />
                )}
              </div>
              {/* <CustomerBG /> */}
            </>
          )}
        </div>
      </Row>
    </>
  );
};

export default CustomizationTable;
