import { useEffect, useState } from "react";
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";
import Select from "react-select";
import timezone from "../TimeZone";
function RestaurantProfileModal({ restaurantModal, restaurantToggle }) {
  const [availability, setAvailability] = useState("");
  const [timeZones, setTimeZones] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Restaurant Details Added");
    restaurantToggle();
  };

  useEffect(() => {
    let temp = [];
    timezone?.map((ele) => {
      temp.push({ value: ele?.text, label: ele?.text });
    });
    setTimeZones(temp);
  }, [timezone]);

  return (
    <div>
      <Modal isOpen={restaurantModal}>
        <ModalHeader toggle={restaurantToggle}>Add Restaurant Details</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Restaurant Name</Label>
                  <Input
                    type="name"
                    placeholder="Restaurant Name"
                    required
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Address</Label>
                  <Input type="name" placeholder="Address" required></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>City</Label>
                  <Input type="name" placeholder="City" required></Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Country</Label>
                  <Input type="name" placeholder="Country" required></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Phone</Label>
                  <Input type="tel" placeholder="Phone" required></Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Email</Label>
                  <Input type="email" placeholder="Email" required></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                {/* <FormGroup>
                  <Label>Time Zone</Label>
                  <Input type="select" required>
                    <option>Asia</option>
                    <option>Europe</option>
                    <option>America</option>
                  </Input>
                </FormGroup> */}
                <FormGroup>
                  <Label for="timeZoneSelect">Time Zone</Label>

                  <Select
                    options={timeZones}
                    defaultValue={null}
                    name="timeZone"
                    id="timeZoneSelect"
                    placeholder="Select Time Zone"
                    isSearchable={true}
                  />
                </FormGroup>
              </Col>
              {/* <Col md="6">
                <FormGroup>
                  <Label>Facebook URL</Label>
                  <Input
                    type="text"
                    placeholder="facebook URL"
                    required
                  ></Input>
                </FormGroup>
              </Col> */}
            </Row>
            {/* <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Twitter URL</Label>
                  <Input type="text" placeholder="Twitter URL" required></Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Instagram URL</Label>
                  <Input
                    type="text"
                    placeholder="Instagram URL"
                    required
                  ></Input>
                </FormGroup>
              </Col>
            </Row> */}

            <div className="d-flex justify-content-end">
              <Button color="primary">Add</Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default RestaurantProfileModal;
