import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getAllMerchantAction } from "store/actions/restaurantAction";

const MerchantDrop = ({ merchant, setMerchant, setFilterValue }) => {
  const dispatch = useDispatch();
  const { merchantData } = useSelector((state) => state.restaurant);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleSelect = (value) => {
    setMerchant(value);
    setFilterValue("merchant");
  };

  useEffect(() => {
    dispatch(getAllMerchantAction());
  }, []);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
      <DropdownToggle
        color="danger"
        className="btn btn-danger btn-block my-3my-lg-0"
        caret
      >
        {merchant?.name || "Select Merchant"}
      </DropdownToggle>
      <DropdownMenu>
        {merchantData?.map((merchant) => {
          return (
            <>
              <DropdownItem onClick={() => handleSelect(merchant)}>
                {merchant?.name}
              </DropdownItem>
            </>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default MerchantDrop;
