import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { login } from "store/actions/authActions";
import Mpink from "../../assets/img/Mpink.png";
import { useHistory } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(false);

  useEffect(() => {
    let authData = localStorage.getItem("userSignUp");
    let userData = JSON.parse(authData);
    setEmail(userData?.email);
    setPassword(userData?.password);
  }, []);

  return (
    <Col lg="5" md="7" className="login">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-4">
          <div className=" d-flex flex-column justify-content-center align-items-center">
            <img
              src={Mpink}
              alt="logo"
              onClick={() => history.push("/")}
              className="mb-3 w-25"
            />
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3 className="title">Welcome</h3>
              <p className="typography">
                Enter your credentials to access your account
              </p>
            </div>
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              let creds = {
                email: email,
                password: password,
              };
              dispatch(login(creds));
            }}
          >
            <FormGroup className="mb-3">
              <Label className="form_label">Email</Label>
              <Input
                placeholder="example@gmail.com"
                type="email"
                autoComplete="new-email"
                defaultValue={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="mb-0">
              <Label className="form_label">Password</Label>
              <div className="login__password">
                <Input
                  placeholder="Password"
                  // type="password"
                  type={eye ? "text" : "password"}
                  autoComplete="new-password"
                  defaultValue={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                {eye ? (
                  <FaEyeSlash
                    className="eye_icon"
                    onClick={() => setEye(!eye)}
                  />
                ) : (
                  <FaEye className="eye_icon" onClick={() => setEye(!eye)} />
                )}
              </div>
            </FormGroup>
            <Row className="mt-0 mx-2 justify-content-end">
              <Link to="/auth/forget-password" className="pointer">
                <small>Forgot password?</small>
              </Link>
            </Row>
            <Button
              className="mt-3 mb-1 w-100 login__btn"
              type="submit"
              disabled={!email || !password || loading}
            >
              {loading ? <Spinner size="sm" /> : "Login"}
            </Button>
            <Row className="mt-0 mb-2 mx-2 justify-content-end">
              <Link to="/auth/sign-up" className="pointer">
                <small>Don't have an account ? Sign up</small>
              </Link>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Login;
