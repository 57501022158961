import React, { useState } from "react";
import { Button, Col, DropdownItem, Row } from "reactstrap";
import moment from "moment";
import { updateNotification } from "store/actions/RequestActions";
import { useDispatch } from "react-redux";
import { getNextNotifications } from "store/actions/RequestActions";

const Notfications = ({ data, restaurantID, route }) => {
  const dispatch = useDispatch();

  const handleNext = () => {
    const obj = data[data?.length - 1];
    dispatch(getNextNotifications(restaurantID, obj, () => {}));
  };

  return (
    <>
      {data.length > 0 ? (
        data?.slice(0, 5)?.map((notif, index) => {
          const timestamp = notif?.createdAt;
          const unixTimestamp =
            timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
          const date = moment.unix(unixTimestamp / 1000);
          const formattedDateTime = date.format("MMM DD HH:mma");

          return (
            <React.Fragment key={index}>
              {" "}
              <Row className="d-flex px-3 mt-2 cursor-pointer">
                <Col md={3} className="d-flex justify-content-center">
                  <span>
                    <img
                      className="avatar avatar-sm rounded-circle mt-1"
                      src={require("../../assets/img/theme/team-4-800x800.jpg")}
                      alt="profile"
                    />
                  </span>
                </Col>

                <Col>
                  <span className="text-wrap mb-2 fs-14">
                    <b>{notif?.userName}</b> {notif?.message}
                  </span>
                  <div className="d-flex justify-content-between fs-12 mt-2">
                    <small>{formattedDateTime}</small>
                    {notif?.markAsRead !== true && (
                      <small
                        className="text-primary"
                        onClick={() => dispatch(updateNotification(notif.id))}
                      >
                        Mark as Read
                      </small>
                    )}
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          );
        })
      ) : (
        <>
          <Row className="justify-content-center py-4 mx-0">
            No Notifcations
          </Row>
        </>
      )}
      {route == "page" && (
        <div className="float-right mr-4 my-4">
          <Button
            size="sm"
            color="primary"
            disabled={data.length < 15}
            onClick={() => handleNext()}
          >
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

export default Notfications;
