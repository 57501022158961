import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { MdAccountBalance } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import EasypaisaLogo from "../../assets/img/Easypaisa2.png";
import { easypaisaSubscription } from "store/actions/authActions";
import EasypaisaPayment from "components/EasypaisaPayment";

const Easypaisa = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const userData = location?.state?.userData;
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setFile(file);
      } else {
        toast.error("Please select an image file (e.g., .jpg, .png)");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData) {
      setLoading(true);
      dispatch(
        easypaisaSubscription({
          userData,
          paymentImage: file,
          request: "subscription",
          onSuccess: () => {
            setLoading(false);
            history.push("/auth/login");
          },
          onError: () => setLoading(false),
        })
      );
    } else {
      toast.error("User data is not available.");
    }
  };

  return (
    <Col lg="5" md="7">
      <EasypaisaPayment
        file={file}
        handleFileChange={handleFileChange}
        loading={loading}
        handleSubmit={handleSubmit}
      />
    </Col>
  );
};

export default Easypaisa;
