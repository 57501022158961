import { useState } from "react";
import firebase from "../../config/firebase";
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addCheckoutQuestion } from "store/actions/checkoutQuestionAction";
import { editCheckoutQuestion } from "store/actions/checkoutQuestionAction";
const EditCheckoutModal = ({
  editModal,
  editToggle,
  editQuestion,
  setEditQuestion,
}) => {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.checkoutQuestion);
  //   const [questionData, setQuestionData] = useState({
  //     title: "",
  //     type: "",
  //   });
  //   const [isMandatory, setIsMandatory] = useState(false);

  //   const handleInputChange = (event) => {
  //     const { name, value } = event.target;
  //     setQuestionData((prevQuestionData) => ({
  //       ...prevQuestionData,
  //       [name]: value,
  //     }));
  //   };

  const handleCatalogSubmit = (e) => {
    e.preventDefault();
    dispatch(
      editCheckoutQuestion(editQuestion, () => {
        editToggle();
      })
    );
  };

  return (
    <div>
      <Modal isOpen={editModal}>
        <ModalHeader toggle={editToggle}>Edit Question Details</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleCatalogSubmit}>
            <Row>
              <Col>
                <Label className="my-2">Question</Label>

                <Input
                  type="text"
                  required
                  name="title"
                  value={editQuestion.title}
                  placeholder="Question"
                  onChange={(e) =>
                    setEditQuestion({ ...editQuestion, title: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormGroup>
                  <Label>Type</Label>
                  <Input
                    type="select"
                    id="exampleCustomSelect"
                    name="type"
                    required
                    value={editQuestion.type}
                    onChange={(e) =>
                      setEditQuestion({ ...editQuestion, type: e.target.value })
                    }
                  >
                    <option value="" selected disabled>
                      Select Type
                    </option>
                    <option value="marketing">Marketing</option>
                    <option value="order">Order</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <div className="mx-3">
              <Input
                type="checkbox"
                value={editQuestion.isMandatory}
                checked={editQuestion.isMandatory}
                onChange={(e) =>
                  setEditQuestion({
                    ...editQuestion,
                    isMandatory: e.target.checked,
                  })
                }
              />{" "}
              IsMandatory
            </div>
            <Row>
              <Col>
                <div className="d-flex justify-content-end ">
                  <Button color="primary" size="sm" disabled={editLoading}>
                    {editLoading ? <Spinner size="sm" /> : "Update"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditCheckoutModal;
