import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	Col,
	Spinner,
} from "reactstrap";
import { updateStoreTiming } from "store/actions/settingAction";
function EditStoreTimingModal({ editModal, editToggle, index, editData }) {
	const [formData, setFormData] = useState({});
	const [applyAll, setApplyAll] = useState(false);
	const { uid } = useSelector((state) => state.auth);
	const { loading } = useSelector((state) => state.catalog);
	const dispatch = useDispatch();

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleApplyAllChange = (e) => {
		setApplyAll(e.target.checked);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (formData.startTime != formData.endTime) {
			dispatch(
				updateStoreTiming(uid, index, formData, applyAll, () => {
					editToggle();
				})
			);
		} else {
			toast.error("Start & End time can not be the same");
		}
	};

	useEffect(() => {
		setFormData({
			day: editData?.day,
			startTime: editData?.startTime,
			endTime: editData?.endTime,
		});
		if (editModal) {
			setApplyAll(false);
		}
	}, [editData]);
	return (
		<div>
			<Modal isOpen={editModal}>
				<ModalHeader toggle={editToggle}>
					{editData?.startTime || editData?.endTime
						? "Update Store Timings"
						: "Add Store Timings"}
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit}>
						<Row>
							<Col>
								<FormGroup>
									<Label for="location">Days</Label>
									<Input
										disabled
										type="text"
										id="exampleCustomSelect"
										name="day"
										required
										value={formData.day}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								{" "}
								<FormGroup>
									<Label for="start-time">Start Time</Label>
									<Input
										type="time"
										name="startTime"
										id="date-inp"
										placeholder="Start time"
										onChange={handleChange}
										value={formData.startTime}
										required
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								{" "}
								<FormGroup>
									<Label for="start-time">End Time</Label>
									<Input
										type="time"
										name="endTime"
										id="date-inp"
										placeholder="End time"
										onChange={handleChange}
										value={formData.endTime}
										required
									/>
								</FormGroup>
							</Col>
						</Row>

						<div className="form-check w-100 p-0 d-flex align-items-center justify-content-start">
							<Input
								type="checkbox"
								className="form-check-input timing-modal-checkbox m-0"
								id="applyAllCheckbox"
								checked={applyAll}
								onChange={handleApplyAllChange}
							/>
							<label
								className="form-check-label timing-modal-checkbox-label ml-2"
								htmlFor="applyAllCheckbox"
							>
								Apply to all days
							</label>
						</div>
						<div className="d-flex justify-content-end">
							<Button color="primary" disabled={loading}>
								{loading ? (
									<Spinner size="sm" />
								) : editData?.startTime || editData?.endTime ? (
									"Update"
								) : (
									"Add"
								)}
							</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default EditStoreTimingModal;
