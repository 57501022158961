import Features from "views/Landing/Features";
import Industries from "views/Landing/Industries";
import LandingHero from "views/Landing/LandingHero";
import Pricing from "views/Landing/Pricing";
import Testimonials from "views/Landing/Testimonials";

const LandingPage = (props) => {
  return (
    <div>
      <LandingHero />
      {/* <Testimonials /> */}
      <Testimonials />
      <Industries />
      <Features />
      <Pricing />
    </div>
  );
};

export default LandingPage;
