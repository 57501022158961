import { cardSuperAdminData } from "helpers/headerCardsData";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Container, Row } from "reactstrap";
import { getRestaurantStatsById } from "store/actions/statsActions";
import { getRestaurantStats } from "store/actions/statsActions";
import requestIcon from "../../assets/img/DashboardIcons/requestIcon.svg";
import customersIcon from "../../assets/img/DashboardIcons/customersIcon.svg";
import cartIcon from "../../assets/img/DashboardIcons/cartIcon.svg";
import orderIcon from "../../assets/img/DashboardIcons/orderIcon.svg";
import dollarIcon from "../../assets/img/DashboardIcons/dollarIcon.svg";
import up from "../../assets/img/DashboardIcons/up.svg";
import down from "../../assets/img/DashboardIcons/down.png";
import { getRestaurantCurrency } from "store/actions/orderActions";

const HeaderCards = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { restaurantStats, loading, currentRestaurantStats } = useSelector(
    (state) => state.stats
  );
  const { restaurantCurrency } = useSelector((state) => state.requests);

  useEffect(() => {
    dispatch(getRestaurantCurrency(user?.restaurantID));
  }, [user?.restaurantID]);

  const cardSuperAdminData = [
    {
      card_no: restaurantStats?.totalCustomers?.total || 0,
      card_topIcon: customersIcon,
      // card_topIcon: "fa-solid fa-users",
      card_name: "Total Customers",
      card_percent: `${
        restaurantStats?.totalCustomers?.percentageChangeCustomer || 0
      }% this Month`,
      card_text:
        restaurantStats?.totalCustomers?.customersCurrentMonth?.toFixed(1) || 0,
      cardBottom_icon: `${
        restaurantStats?.totalCustomers?.changeDirectionCustomer || ""
      }`,
    },
    {
      card_no: restaurantStats?.totalMerchants?.totalMerchants || 0,
      card_topIcon: cartIcon,
      // card_topIcon: "fa-solid fa-users",
      card_name: "Total Merchants",
      card_percent: `${
        restaurantStats?.totalMerchants?.percentageChangeMerchant || 0
      }% this Month`,
      card_text:
        restaurantStats?.totalMerchants?.merchantsCurrentMonth?.toFixed(1) || 0,
      cardBottom_icon: `${
        restaurantStats?.totalCustomers?.changeDirectionMerchant || ""
      }`,
    },
    {
      card_no: restaurantStats?.totalChurnRate?.churnRate || 0,
      card_topIcon: orderIcon,
      // card_topIcon: "fa-solid fa-users",
      card_name: "Churn Rate",
      card_percent: `${
        restaurantStats?.totalChurnRate?.percentageChangeChurn || 0
      }% this Month`,
      card_text:
        restaurantStats?.totalChurnRate?.churnRateCurrent?.toFixed(1) || 0,
      cardBottom_icon: `${
        restaurantStats?.totalChurnRate?.changeDirectionChurn || ""
      }`,
    },

    {
      card_no: restaurantStats?.totalLTV?.ltv || 0,
      card_topIcon: dollarIcon,
      // card_topIcon: "fa-solid fa-users",
      card_name: "Life Time Value",
      card_percent: `${
        restaurantStats?.totalLTV?.changePercentage || 0
      }% this Month`,
      card_text: restaurantStats?.totalLTV?.ltvCurrentMonth || 0,
      cardBottom_icon: `${restaurantStats?.totalLTV?.changeDirection || ""}`,
    },
  ];

  const cardRestaurantData = [
    {
      card_no: currentRestaurantStats?.customers?.totalCustomers || 0,
      // card_topIcon: "fa-solid fa-users",
      card_topIcon: customersIcon,
      card_name: "Total Customers",
      card_percent: `${
        currentRestaurantStats?.cutomers?.totalCustomersCurrentMonth || 0
      }% this Month`,
      card_text:
        currentRestaurantStats?.customers?.percentageChangeCustomers?.toFixed(
          1
        ) || 0,
      cardBottom_icon: `${
        currentRestaurantStats?.customers?.changeDirectionCustomers || ""
      }`,
    },
    {
      card_no: currentRestaurantStats?.orders?.totalOrders || 0,
      // card_topIcon: "fa-solid fa-circle-check",
      card_topIcon: cartIcon,
      card_name: "Total Orders",
      card_percent: `${
        currentRestaurantStats?.orders?.totalOrdersCurrentMonth || 0
      }% this Month`,
      card_text:
        currentRestaurantStats?.orders?.percentageChangeOrders?.toFixed(1) || 0,
      cardBottom_icon: `${
        currentRestaurantStats?.orders?.changeDirectionOrders || ""
      }`,
    },
    {
      card_no:
        (restaurantCurrency ? restaurantCurrency : "") +
        " " +
        (currentRestaurantStats?.averageOrderCost?.averageOrderCost || 0),
      // card_topIcon: "fa-solid fa-users",
      card_topIcon: orderIcon,
      card_name: "Average Order Size",
      card_percent: `${
        currentRestaurantStats?.averageOrderCost
          ?.averageOrderCostCurrentMonth || 0
      }% this Month`,
      card_text:
        currentRestaurantStats?.averageOrderCost?.percentageChangeAverageCost?.toFixed(
          1
        ) || 0,
      cardBottom_icon: `${
        currentRestaurantStats?.averageOrderCost?.changeDirectionAverage || ""
      }`,
    },
    {
      card_no: currentRestaurantStats?.requests?.totalRequests || 0,
      card_topIcon: requestIcon,
      // card_topIcon: "fa-solid fa-dollar-sign",
      card_name: "Requests",
      card_percent: `${
        currentRestaurantStats?.requests?.totalRequestsCurrentMonth || 0
      }% this Month`,
      card_text:
        currentRestaurantStats?.requests?.percentageChangeRequests?.toFixed(
          1
        ) || 0,
      cardBottom_icon: `${
        currentRestaurantStats?.requests?.changeDirectionRequests || ""
      }`,
    },
  ];

  console.log({ restaurantStats });

  useEffect(() => {
    if (user?.type == "admin") {
      dispatch(getRestaurantStats());
    } else if (user?.type == "restaurant") {
      dispatch(getRestaurantStatsById(user?.restaurantID));
    }
  }, []);
  return (
    <Container fluid className="mt-5">
      <Card className="header-cards card">
        <Row className="row ">
          {user?.type === "restaurant" &&
            cardRestaurantData?.map((item, key) => {
              return (
                <Col
                  lg={3}
                  md={6}
                  xs={12}
                  key={key}
                  className="header-cards__col"
                >
                  <Card className="p-4 border-0 w-100">
                    <div className="d-flex justify-content-between align-items-center">
                      <h1 className="mb-0">
                        {item?.card_no ? item?.card_no : 0}
                      </h1>
                      <img
                        src={item?.card_topIcon}
                        alt="card image"
                        width={50}
                      />
                    </div>
                    <span className="fs-14 mb-1 text-nowrap">
                      {item?.card_name}
                    </span>
                    <div className="d-flex justify-content-between align-items-center fs-12">
                      <div>
                        <img
                          src={item?.cardBottom_icon == "Increase" ? up : down}
                          alt="card image"
                        />
                        <span>{item?.card_text}</span>
                      </div>
                      <span>{item?.card_percent}</span>
                    </div>
                  </Card>
                  <div className="divider"></div>
                </Col>
              );
            })}
          {user?.type === "admin" &&
            cardSuperAdminData?.map((item) => {
              return (
                <Col lg={3} md={6} xs={12} className="header-cards__col">
                  <Card className="p-4 border-0 w-100">
                    <div className="d-flex justify-content-between align-items-center">
                      <h1 className="mb-0">
                        {item?.card_no ? item?.card_no : 0}
                      </h1>
                      <img
                        src={item?.card_topIcon}
                        alt="card image"
                        width={50}
                      />
                    </div>
                    <span className="fs-16 mb-2">{item?.card_name}</span>
                    <div className="d-flex justify-content-between align-items-center fs-12">
                      <div>
                        <img
                          src={item?.cardBottom_icon == "Increase" ? up : down}
                          alt="card image"
                        />
                        <span>{item?.card_text}</span>
                      </div>
                      <span>{item?.card_percent}</span>
                    </div>
                  </Card>
                  <div className="divider"></div>
                </Col>
              );
            })}
        </Row>
      </Card>
    </Container>
  );
};

export default HeaderCards;
