import React from "react";
import { FaUser } from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import EasypaisaLogo from "../assets/img/Easypaisa2.png";

const EasypaisaPayment = ({
  card = true,
  file,
  handleFileChange,
  loading,
  disabled = false,
  handleSubmit,
}) => {
  return (
    <div className={`${card && "rounded login bg-secondary shadow"} border-0`}>
      <div className={`${card && "card-body px-lg-5 py-lg-4"}`}>
        {card && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={EasypaisaLogo} alt="easypaisa" className="mb-3 w-50" />
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="typography text-center">
                Pay to Easypaisa account using the following details and attach
                a screenshot for confirmation.
              </p>
            </div>
          </div>
        )}
        <Label className="form_label text-center mb-2">Payment Details</Label>
        <div className="border rounded p-2 mb-2 bg-secondary">
          <div className="d-flex align-items-center">
            <FaUser />
            <div className="ml-3">
              <p className="typography mb-0">Account Holder Name</p>
              <h3>Furqan Zahid</h3>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <MdAccountBalance />
            <div className="ml-3">
              <p className="typography mb-0">Easypaisa Account Number</p>
              <h3>0123 1231234</h3>
            </div>
          </div>
        </div>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label className={`form_label mb-2 ${!card && "mt-2"}`}>
              Payment Screenshot
            </Label>
            <Input
              type="file"
              id="paymentImage"
              onChange={handleFileChange}
              className="d-none"
            />
            <label
              htmlFor="paymentImage"
              className="w-100 border p-2 rounded cursor-pointer"
            >
              {file ? file?.name : "Choose a file"}
            </label>
            {file && (
              <div className="mt-2">
                <Label className="form_label mb-2 d-block">Preview</Label>
                <div className="rounded overflow-hidden text-center">
                  <img
                    src={URL.createObjectURL(file)}
                    alt="..."
                    style={{ maxHeight: "300px" }}
                  />
                </div>
              </div>
            )}
          </FormGroup>
          <Button
            className="w-100 login__btn"
            type="submit"
            disabled={!file || disabled || loading}
          >
            {loading ? <Spinner size="sm" /> : "Submit"}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default EasypaisaPayment;
