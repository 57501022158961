import React, { useState, useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const [visiblePages, setVisiblePages] = useState(3);

  useEffect(() => {
    const updateVisiblePages = () => {
      if (window.innerWidth < 400) {
        setVisiblePages(2);
      } else {
        setVisiblePages(3);
      }
    };

    updateVisiblePages();
    window.addEventListener("resize", updateVisiblePages);

    return () => window.removeEventListener("resize", updateVisiblePages);
  }, []);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(currentPage - 1, 0);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages - 1);

    if (currentPage >= totalPages - visiblePages) {
      startPage = Math.max(totalPages - visiblePages, 1);
      pageNumbers.push(
        <PaginationItem key={0} active={currentPage === 0}>
          <PaginationLink href="#" onClick={() => onPageChange(0)}>
            1
          </PaginationLink>
        </PaginationItem>
      );

      if (startPage > 1) {
        pageNumbers.push(
          <PaginationItem key="start-ellipsis" disabled>
            <PaginationLink href="#">...</PaginationLink>
          </PaginationItem>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink href="#" onClick={() => onPageChange(i)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }

    if (endPage < totalPages - 1) {
      if (endPage < totalPages - 2) {
        pageNumbers.push(
          <PaginationItem key="end-ellipsis" disabled>
            <PaginationLink href="#">...</PaginationLink>
          </PaginationItem>
        );
      }

      pageNumbers.push(
        <PaginationItem
          key={totalPages - 1}
          active={currentPage === totalPages - 1}
        >
          <PaginationLink href="#" onClick={() => onPageChange(totalPages - 1)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return pageNumbers;
  };

  return (
    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <PaginationItem disabled={currentPage === 0}>
        <PaginationLink href="#" onClick={() => onPageChange(currentPage - 1)}>
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
      {renderPageNumbers()}
      <PaginationItem
        disabled={currentPage === totalPages - 1 || totalPages === 0}
      >
        <PaginationLink href="#" onClick={() => onPageChange(currentPage + 1)}>
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationComponent;
