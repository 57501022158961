import firebase from '../../config/firebase';
import { toast } from 'react-toastify';
export const postContact = (name,email,message) => async dispatch => {
	try{
        dispatch(submitContactLoader(true));
        await firebase
        .firestore()
        .collection("contacts")
        .add({
          name,email,message
        });
    toast.success("Message has been send")
    setTimeout(()=>{
        dispatch(submitContactLoader(false));
    },2000)
    }catch(error){
console.log(error.message);
dispatch(submitContactLoader(false));
    }
};
const submitContactLoader = data => async dispatch => {
	dispatch({
		type: 'CONTACT_LOADER',
		payload: data,
	});
};