import React, { useEffect, useState } from "react";
import { Container} from "reactstrap";
import LandingNavbar from "./LandingNavbar";

const LandingHeader = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-white header ">
      <Container fluid>
        <LandingNavbar />
      </Container>
    </div>
  );
};

export default LandingHeader;
