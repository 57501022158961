import React from "react";
import Flickity from "react-flickity-component";
import { Card, CardBody, CardText } from "reactstrap";
import testimonial1 from "../../assets/img/LandingPage/testimonial1.jpg";
import testimonial2 from "../../assets/img/LandingPage/testimonial2.jpg";
import testimonial3 from "../../assets/img/LandingPage/testimonial3.jpg";
import testimonial4 from "../../assets/img/LandingPage/testimonial4.jpg";

const Testimonials = () => {
  const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
  };

  const contentCards = [
    {
      src: testimonial1,
      desc: "“We're regulars at this restaurant, and the new management system has made our visits even more enjoyable. Booking a table is so easy, and the interactive menus let us explore new dishes. It's clear the restaurant cares about our experience”",
      user: "Mike and Lisa H., Regular Customers",
      address: "6 Salon, MI, USA",
    },
    {
      src: testimonial2,
      desc: "“I'm a foodie who enjoys exploring new restaurants, and this system has made dining out a breeze. I can check availability, view menus, and make reservations all from my phone. It's incredibly convenient.”",
      user: "Grace L., Customer",
      address: "Hooker & Young, Newcastle, UK",
    },
    {
      src: testimonial3,
      desc: "“I've reviewed countless restaurants, and the ones using this management system stand out. The attention to detail in menu management, reservation handling, and overall customer experience is impressive. It's a sign of a well-run establishment.”",
      user: "Mark D., Restaurant Critic",
      address: "Dylan Bradshaw, Dublin",
    },
    {
      src: testimonial4,
      desc: "“Switching to this Restaurant Management System was a game-changer for my business. It simplified everything – from managing reservations to tracking inventory. The real-time insights helped me make informed decisions, and my customers love the seamless experience. Highly recommend!”",
      user: "John T, Restaurant Owner",
      address: "The Beauty Lounge, MI, USA",
    },
  ];
  function Carousel() {
    return (
      <Flickity options={flickityOptions} data-aos="zoom-in">
        {contentCards?.map((item,index) => {
          return (
            <Card key={index} className="mx-2 carosel_card">
              <img alt="Sample" src={item.src} className="carosel_card-img" />
              <CardBody>
                <CardText className="carosel_card-desciption">
                  {item.desc}
                </CardText>
                <CardText className="carosel_card-title mb-1">
                  {item.user}
                </CardText>
                <CardText className="carosel_card-address">
                  {item.address}
                </CardText>
              </CardBody>
            </Card>
          );
        })}
      </Flickity>
    );
  }
  return (
    <div className="my-5 pb-0 testimonials flickty_carosel">
      {/* <Partners /> */}
      <div data-aos="fade-up">
        <Carousel />
      </div>
    </div>
  );
};

export default Testimonials;
