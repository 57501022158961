import firebase from "../../config/firebase";
import algoliasearch from "algoliasearch";
import { createNullCache } from "@algolia/cache-common";
const client = algoliasearch("GPYJEAVUBP", "b1f999bc5b264defe930762391d240c6", {
  responsesCache: createNullCache(),
});

export const getAllMerchantAction = (id, category) => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("users")
      .where("type", "==", "restaurant")
      .onSnapshot(async (query) => {
        let temp = [];
        if (!query.empty) {
          for (let doc of query.docs) {
            temp.push({ id: doc.id, ...doc.data() });
          }
        }
        dispatch({ type: "GET_ALL_MERCHANT", payload: temp });
      });
  } catch (err) {
    alert(err.message);
  }
};
export const getAllRestaurant =
  (search, hitsPerPage, currentPage) => async (dispatch) => {
    dispatch(restaurantLoader(true));
    const index = client.initIndex("users");
    index
      .search(search, {
        filters: "isDeleted:false AND type:restaurant",
        hitsPerPage: hitsPerPage,
        page: currentPage,
      })
      .then((response) => {
        let { hits, ...rest } = response;
        dispatch({
          type: "GET_RESTAURANT",
          payload: hits,
        });
        dispatch({ type: "RESTAURANT_DETAILS", payload: rest });
        dispatch(restaurantLoader(false));
      });
  };

export const updateMerchant = (id, obj, onSuccess) => async (dispatch) => {
  dispatch(restaurantLoader(true));
  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .update(obj)
    .then(() => {
      dispatch(restaurantLoader(false));
      onSuccess();
      dispatch({
        type: "UPDATE_RESTAURANT",
        payload: {
          id,
          ...obj,
        },
      });
    })
    .catch((err) => {
      alert(err);
      dispatch(restaurantLoader(false));
    });
};

export const deleteRestaurant = (id, onSuccess) => async (dispatch) => {
  dispatch(restaurantLoader(true));
  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .update({
      isDeleted: true,
    })
    .then(() => {
      dispatch(restaurantLoader(false));
      onSuccess();
      dispatch({ type: "DELETE_RESTAURANT", payload: id });
    })
    .catch((err) => {
      alert(err);
      dispatch(restaurantLoader(false));
    });
};
export const getRestaurantImages = (ID) => async (dispatch) => {
  firebase
    .firestore()
    .collection("users")
    .doc(ID)
    .onSnapshot((query) => {
      let data = query.data();
      let images = data?.restaurantImages;
      let logo = data?.restaurantLogo;
      let adminColor = data?.adminColor;
      let customerColor = data?.customerColor;
      let languages = data?.languages;
      let lightBg = data?.customerBG;
      let darkBg = data?.customerDarkBG;
      dispatch({
        type: "Restaurant_Media",
        payload: {
          images,
          logo,
          adminColor,
          customerColor,
          languages,
          lightBg,
          darkBg,
        },
      });
    });
};

export const addBackground =
  (ID, mode, file, onSuccess = () => {}) =>
  async (dispatch) => {
    const fileName = file?.name;
    const fileName1 = fileName.split(".")[0];
    const fileName2 = fileName1.toLowerCase();

    const storageRef = firebase
      .storage()
      .ref("restaurant_BG/")
      .child(fileName2);
    const existingFileRef = firebase
      .storage()
      .ref()
      .child(`restaurant_BG/${fileName2}`);
    try {
      await existingFileRef.getDownloadURL();
      await existingFileRef.delete();
    } catch (error) {
      console.log(error);
    }
    await storageRef.put(file);
    const urlFeatured = await storageRef.getDownloadURL();

    if (mode === "dark") {
      await firebase.firestore().collection("users").doc(ID).update({
        customerDarkBG: urlFeatured,
      });
    } else {
      await firebase.firestore().collection("users").doc(ID).update({
        customerBG: urlFeatured,
      });
    }

    onSuccess();
  };

export const deleteBG =
  (obj, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    const { restaurantID, type, imageUrl } = obj;
    try {
      if (type == "light") {
        firebase.firestore().collection("users").doc(restaurantID).update({
          customerBG: "",
        });
      } else {
        firebase.firestore().collection("users").doc(restaurantID).update({
          customerDarkBG: "",
        });
      }
      const storage = firebase.storage();
      const urlParts = imageUrl.split("?")[0].split("/");
      const filePath = decodeURIComponent(urlParts[urlParts.length - 1]);

      const fileRef = storage.ref().child(filePath);

      await fileRef.delete();
      onSuccess();
      // console.log("File deleted successfully.");
    } catch (error) {
      onError();
      console.error("Error deleting file:", error);
    }
  };

const restaurantLoader = (data) => async (dispatch) => {
  dispatch({
    type: "RESTAURANT_LOADER",
    payload: data,
  });
};
export const getPlatFormFeedbacks = (Count) => async (dispatch) => {
    try{
      dispatch(feedbackLoader(true));
  firebase
      .firestore()
      .collection("platformFeedback")
      .limit(Count)
      .onSnapshot((query) => {
        let feedbacks = [];
        for (let doc of query.docs) {
          feedbacks.push({ id: doc.id, ...doc.data() });
        }
        dispatch(feedbackLoader(false));
        dispatch({
          type: "PLATFORM_FEEDBACKS",
          payload: feedbacks,
        });
      });
    }catch(error){
      dispatch(feedbackLoader(false));
      console.log(error);
    }
};
const feedbackLoader = (data) => async (dispatch) => {
  dispatch({
    type: "FEEDBACK_LOADER",
    payload: data,
  });
};