import React, { useEffect } from "react";
import LandingFooter from "views/Landing/LandingFooter";
import LandingHeader from "views/Landing/LandingHeader";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import routes from "routes";
import { useSelector } from "react-redux";

const LandingLayout = (props) => {
  const {uid}=useSelector((state)=>state.auth);
  const mainContent = React.useRef(null);
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/public") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (location.pathname == "/") {
      history.push("/home");
    }
  }, [location]);
if(uid){
  history.push('/admin/index');
}
  return (
    <div className="landing" ref={mainContent}>
      <LandingHeader />
      <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to="/" />
      </Switch>
      <LandingFooter />
    </div>
  );
};

export default LandingLayout;
