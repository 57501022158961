import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, Table, Row, Button } from "reactstrap";
import { fetchStoreTimings } from "store/actions/settingAction";

function StoreTimingsTable({ editToggle, setEditData, setIndex }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { storeTimings } = useSelector((state) => state?.setting);
	const { uid } = useSelector((state) => state.auth);

	const [localTimings, setLocalTimings] = useState([]);

	useEffect(() => {
		dispatch(fetchStoreTimings(uid));
	}, [dispatch, uid]);

	useEffect(() => {
		setLocalTimings(storeTimings);
	}, [storeTimings]);

	const returnDate = (startTime) => {
		if (startTime) {
			const date = new Date(`1970-01-01T${startTime}:00`);
			return date.toLocaleTimeString("en-US", {
				hour: "numeric",
				minute: "numeric",
				hour12: true,
			});
		}
		return "";
	};

	return (
		<>
			{/* Table */}
			<Row>
				<div className="col">
					<Card className="shadow">
						<CardHeader className="d-lg-flex justify-content-between">
							<div className="d-flex align-items-center">
								<Button
									size="sm"
									color="primary"
									onClick={() => {
										history.push("/admin/venue-setting-menu");
									}}
								>
									<i className="fas fa-arrow-left"></i>
								</Button>

								<h3 className="pt-2">Store Timings</h3>
							</div>
						</CardHeader>

						<Table className="align-items-center table-flush" responsive>
							<thead className="thead-light">
								<tr>
									<th scope="col">Days</th>
									<th scope="col">Start Time</th>
									<th scope="col">End Time</th>
									<th scope="col">Actions</th>
								</tr>
							</thead>
							<tbody>
								{localTimings?.map((el, id) => (
									<tr key={id}>
										<th scope="row">{el?.day}</th>
										<td>{returnDate(el?.startTime)}</td>
										<td>{returnDate(el?.endTime)}</td>
										<td>
											<Button
												className="btn-sm"
												color="primary"
												onClick={() => {
													setEditData(el);
													setIndex(id);
													editToggle();
												}}
											>
												{el?.startTime || el?.endTime ? "Edit" : "Add"}
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Card>
				</div>
			</Row>
		</>
	);
}

export default StoreTimingsTable;
