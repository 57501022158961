import firebase from "../../config/firebase";
import algoliasearch from "algoliasearch";
import { createNullCache } from "@algolia/cache-common";
const client = algoliasearch("GPYJEAVUBP", "b1f999bc5b264defe930762391d240c6", {
  responsesCache: createNullCache(),
});

let OrderIndex = client.initIndex("orders");
let usersIndex = client.initIndex("users");

export const getAllCustomers =
  (ID, search, gender, value, hitsPerPage) => async (dispatch) => {
    const minAge = value[0];
    const maxAge = value[1];
    dispatch(customerLoader(true));
    OrderIndex.search("", {
      filters: `restaurantID:${ID}`,
      hitsPerPage: 1000000,
    }).then(async (response) => {
      let { hits, ...rest } = response;
      const customerIDs = hits.map((hit) => hit.customerID);
      const uniqueCustomerIDs = [...new Set(customerIDs)];
      const uniqueCustomers = [];
      for (const customerID of uniqueCustomerIDs) {
        let customerFilters = `objectID:${customerID} AND type:'customer' AND (age >= ${minAge} AND age <= ${maxAge})`;
        if (gender !== "all" && gender !== "") {
          customerFilters += ` AND gender:${gender}`;
        }
        const customerResponse = await usersIndex.search(search, {
          filters: customerFilters,
          hitsPerPage: hitsPerPage,
        });
        if (customerResponse.hits.length > 0) {
          uniqueCustomers.push(customerResponse.hits[0]);
        }
      }
      dispatch({
        type: "GET_ALL_CUSTOMER",
        payload: uniqueCustomers,
      });
      // update rest.nbHits and rest.nbPages based on the number of uniqueCustomers fetched
      rest.nbHits = uniqueCustomers.length;
      rest.nbPages = Math.ceil(rest.nbHits / hitsPerPage);
      rest.hitsPerPage = hitsPerPage;
      // dispatch the updated rest object to the redux store
      dispatch({
        type: "CUSTOMER_DETAILS",
        payload: rest,
      });

      dispatch(customerLoader(false));
    });
  };

const customerLoader = (data) => async (dispatch) => {
  dispatch({
    type: "CUSTOMER_LOADER",
    payload: data,
  });
};
