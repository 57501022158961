import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import hero from "../../assets/img/LandingPage/hero.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LandingHero = () => {
  const history = useHistory();


  // const mainImgOpacity = 1 - scrollY / 300;

  const data = [
    {
      title: "Are you ready to revolutionise the way you run your restaurant?",
      desc: "Our cutting-edge Restaurant Management System is here to simplify your operations.",
    },
    {
      title: "Ready to take your restaurant to the next level?",
      desc: "Join the countless satisfied restaurant owners who have already experienced the benefits of our Restaurant Management System.",
    },
    {
      title: "Get Started Today",
      desc: "Say goodbye to the complexities of traditional restaurant management ",
    },
  ];

  return (
    <div className="landing-hero">
      <Container fluid>
        <Row className="landing-hero__top-row">
          <Col className=" hero__left pl-4 pr-2">
            <p className="hero__left-top_tag mb-1">
              Restaurant Software for Restaurant Businesses
            </p>
            <h3 className="hero__left-top_tagline">
              Welcome to Our Restaurant Management System
            </h3>
            <div className="my-4">
              {data.map((doc, index) => {
                return (
                  <div key={index} className="my-4">
                    <h5
                      className="hero__left-bottom_tagline"
                      style={{
                        color: index !== 2 ? "#fc968b" : "#ff4856",
                      }}
                    >
                      {doc.title}
                    </h5>
                    <p className="hero__left-bottom_tag mt-3">{doc.desc}</p>
                  </div>
                );
              })}
            </div>
            <div className="mt-5 d-flex flex-column flex-sm-row">
              <Button
                color="danger"
                className="p-3"
                onClick={() => history.push("/auth/login")}
              >
                Get Started
              </Button>
            </div>
          </Col>
          <Col className="hero__right">
            <img
              src={hero}
              alt="hero"
              className="hero__right-bg"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingHero;
