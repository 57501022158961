import RestaurantProfileModal from "components/Modals/RestaurantProfileModal";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Alert,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import {
  clearAllNotifications,
  getUnreadNotfications,
} from "store/actions/RequestActions";
import { CloseAlert, logout } from "store/actions/authActions";
import NextLogo from "../../assets/img/Mpink.png";
import bell from "../../assets/img/icons/bell.svg";
import bullets from "../../assets/img/icons/bullets.svg";
import Notfications from "./Notfications";

const AdminNavbar = (props) => {
  const { hide, setHide } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const [restaurantModal, setRestaurantModal] = useState(false);
  const { unreadNotifications } = useSelector((state) => state.requests);
  const [visible, setVisible] = useState(true);
  const generateQRCode = () => {
    const url = `https://nexteats-main.web.app/restaurant/${user?.restaurantID}`;
    QRCode.toDataURL(
      url,
      {
        width: 800,
        margin: 2,
        color: {
          dark: "#335383FF",
          light: "#EEEEEEFF",
        },
      },
      (err, url) => {
        if (err) return console.error(err);
        const link = document.createElement("a");
        link.download = `qrcode_${user?.restaurantID}.png`;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    );
  };

  const onDismiss = () => setVisible(false);

  const restaurantToggle = () => {
    setRestaurantModal(!restaurantModal);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const MoveToStoreTiming = () => {
    dispatch(CloseAlert(user?.restaurantID, onDismiss));
    history.push("/admin/store-timings");
  };
  useEffect(() => {
    if (user?.restaurantID) {
      dispatch(getUnreadNotfications(user?.restaurantID));
    }
  }, []);

  return (
    <>
      <Navbar
        className={`navbar-dark ${hide ? "navbar-top-hide" : "navbar-top"}`}
        expand="md"
        id="navbar-main"
      >
        {}
        <Container fluid className="justify-content-end">
          <div className="me-auto d-flex cursor-pointer justify-content-center align-items-center mx-1 navbar__items">
            <div id="google_translate_element" className="mt-3">
              {" "}
            </div>
            <div
              className={hide ? "sidebar-icon-2" : "sidebar-icon "}
              onClick={(e) => {
                e.preventDefault();
                setHide((prevState) => !prevState);
              }}
            >
              <svg
                width="70"
                height="70"
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_351_3111)">
                  <rect
                    x="20"
                    y="16"
                    width="30"
                    height="30"
                    rx="15"
                    fill="white"
                  />
                  <path
                    d={
                      hide
                        ? "M32.4998 23.5L30.7373 25.2625L36.4623 31L30.7373 36.7375L32.4998 38.5L39.9998 31L32.4998 23.5Z"
                        : "M37.5 23.5L39.2625 25.2625L33.5375 31L39.2625 36.7375L37.5 38.5L30 31L37.5 23.5Z"
                    }
                    fill={restaurantMedia.adminColor || "#d70365"}
                  />
                </g>

                <defs>
                  <filter
                    id="filter0_d_351_3111"
                    x="0"
                    y="0"
                    width="70"
                    height="70"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_351_3111"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_351_3111"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>

          {user?.type !== "admin" && (
            <Link onClick={generateQRCode}>Download QR Code </Link>
          )}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            {user?.firsttimelogin &&
              user?.subScriptionStatus === "subscribe" && (
                <Alert color="secondary" isOpen={visible} toggle={onDismiss}>
                  <i className="fas fa-triangle-exclamation text-warning px-1 h-25"></i>{" "}
                  Reminder! Don't forget to Add Restaurant Timings{" "}
                  <Link className="underline" onClick={MoveToStoreTiming}>
                    <u>Adjust store timings here</u>
                  </Link>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() =>
                      dispatch(CloseAlert(user?.restaurantID, onDismiss))
                    }
                  >
                    <span aria-hidden="true" style={{ color: "red" }}>
                      &times;
                    </span>
                  </button>
                </Alert>
              )}
            {/* notification dropdown  */}
            {user?.type === "restaurant" && (
              <UncontrolledDropdown
                nav
                isOpen={isDropdownOpen}
                toggle={toggleDropdown}
              >
                <DropdownToggle nav>
                  <img src={bell} alt="Bell" className="mr-3" />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-arrow custom-dropdown-menu p-0"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                  right
                >
                  <div className="dropdown-header d-flex align-items-center justify-content-between bg-pink w-100">
                    <h4 className="mb-0 text-white">
                      Notifications ({unreadNotifications?.length})
                    </h4>
                    <button
                      className="btn btn-sm text-white"
                      type="button"
                      onClick={() => {
                        dispatch(clearAllNotifications(user?.restaurantID));
                        toggleDropdown();
                        // DropdownToggle();
                      }}
                    >
                      Clear All
                    </button>
                  </div>
                  {/* Add your notification menu items here */}
                  <Notfications
                    data={unreadNotifications}
                    restaurantID={user?.restaurantID}
                    route="modal"
                  />
                  <hr className="my-2" />
                  <div
                    className="dropdown-footer d-flex align-items-center justify-content-center"
                    style={{ cursor: "pointer" }}
                  >
                    <h4
                      onClick={() => {
                        toggleDropdown();
                        history.push("/admin/notifications");
                      }}
                    >
                      View All
                    </h4>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

            {/* profile dropdown  */}
            <UncontrolledDropdown nav>
              <DropdownToggle className="navbar-top__profile-toggler" nav>
                <Media className="align-items-center">
                  {/* <span className="avatar avatar-sm rounded"> */}
                  <img
                    className="avatar avatar-sm rounded"
                    alt="logo"
                    src={user?.restaurantLogo || NextLogo}
                  />
                  {/* </span> */}
                  <Media className="ml-2 d-none d-lg-flex flex-column">
                    <span className="mb-0 text-sm  text-dark font-weight-bold">
                      {user?.name}
                    </span>
                    <span className="mb-0 text-sm text-dark ">
                      {user?.email}
                    </span>
                  </Media>
                </Media>

                <img src={bullets} alt="bullets" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow mt-2" right>
                {user?.type === "restaurant" && (
                  <DropdownItem
                    onClick={() => history.push("/admin/accout-settings")}
                  >
                    <i className="ni ni-single-02" />
                    <span>Restaurant Profile</span>
                  </DropdownItem>
                )}

                <DropdownItem
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
      <RestaurantProfileModal
        restaurantModal={restaurantModal}
        restaurantToggle={restaurantToggle}
      />
    </>
  );
};

export default AdminNavbar;
