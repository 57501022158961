import React from "react";
import { Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const LandingFooter = () => {
  const history=useHistory();
  const footerIcons = [
    {
      src: (
        <svg role="img" title="Twitter" viewBox="0 0 32 32">
          <path
            fill="#fefefe"
            d="M16.064 1.856C8.203 1.852 1.837 8.217 1.837 16.08v.012c0 7.851 6.365 14.216 14.216 14.216h.012-.001c7.863 0 14.225-6.358 14.228-14.225S23.928 1.856 16.064 1.856z"
          ></path>
          <path
            fill="#fefefe"
            d="M16.064 1.856c7.863 0 14.228 6.368 14.225 14.229S23.925 30.31 16.064 30.307h-.012c-7.851 0-14.216-6.365-14.216-14.216v-.012.001c0-7.863 6.366-14.228 14.227-14.224zm8.802 9.574c-.292.097-.58.209-.877.289-.285.077-.573.161-.945.158.719-.503 1.212-1.097 1.483-1.929a7.751 7.751 0 01-1.919.785l-.055.012a.456.456 0 01-.481-.12c-.847-.768-1.847-1.077-2.98-.906a3.572 3.572 0 00-2.973 4.164l-.003-.022c.032.179-.017.222-.192.196-.316-.046-.64-.055-.952-.111-2.415-.43-4.435-1.565-6.04-3.425-.172-.2-.216-.128-.305.054a3.54 3.54 0 00-.353 1.554c0 1.097.491 2.079 1.265 2.738l.005.004c.081.068.196.111.233.242-.543-.029-1.032-.192-1.548-.438.122 1.868 1.07 3.018 2.816 3.564-.512.156-1.006.113-1.546.073.608 1.524 1.692 2.328 3.328 2.488a7.163 7.163 0 01-2.399 1.23l-.051.013c-.885.256-1.792.303-2.787.241.106.074.135.099.168.118 3.372 1.914 6.848 2.088 10.233.223 3.51-1.932 5.151-5.097 5.248-9.077.005-.228.044-.384.243-.534.563-.425 1.059-.922 1.431-1.53.03-.01.058-.029.035-.06-.013-.012-.055.001-.081.003z"
          ></path>
          <path
            fill="#272727"
            d="M24.907 11.487c-.372.608-.868 1.105-1.431 1.53-.198.151-.237.305-.243.534-.096 3.98-1.737 7.146-5.248 9.077-3.387 1.864-6.861 1.693-10.232-.223-.033-.019-.064-.044-.168-.118.997.064 1.901.017 2.787-.241a7.235 7.235 0 002.466-1.257l-.016.012c-1.634-.161-2.717-.964-3.328-2.488.54.04 1.036.083 1.546-.073-1.745-.547-2.694-1.696-2.816-3.564.512.245 1.005.409 1.548.438-.036-.128-.152-.173-.233-.242a3.592 3.592 0 01-.907-4.317l-.009.021c.09-.184.133-.256.305-.054 1.604 1.856 3.624 2.995 6.04 3.425.314.056.636.064.952.111.176.026.225-.018.192-.196a3.569 3.569 0 012.957-4.139l.02-.003c1.137-.17 2.138.14 2.984.908a.46.46 0 00.484.12l-.003.001a7.884 7.884 0 002.012-.817l-.038.02c-.271.832-.764 1.426-1.483 1.929.372 0 .66-.081.945-.158.297-.08.585-.192.877-.289z"
          ></path>
          <path
            fill="#fdfdfd"
            d="M24.866 11.43c.026 0 .068-.015.076 0 .022.031-.005.05-.035.06z"
          ></path>
        </svg>
      ),
      target: "https://google.com",
    },
    {
      src: (
        <svg role="img" title="Instagram" viewBox="0 0 32 32">
          <path
            fill="#fefefe"
            d="M16.064 1.856C8.203 1.852 1.837 8.217 1.837 16.08v.012c0 7.851 6.365 14.216 14.216 14.216h.012-.001c7.863 0 14.225-6.358 14.228-14.225S23.928 1.856 16.064 1.856z"
          ></path>
          <path
            fill="#fefefe"
            d="M16.064 1.856c7.863 0 14.228 6.368 14.225 14.229S23.925 30.31 16.064 30.307h-.012c-7.851 0-14.216-6.365-14.216-14.216v-.012.001c0-7.863 6.366-14.228 14.227-14.224z"
          ></path>
          <path
            fill="#272727"
            d="M16.086 23.985c-1.146.017-2.294.048-3.438-.023-1.29-.079-2.415-.542-3.26-1.565a4.176 4.176 0 01-.819-1.635l-.006-.029c-.201-.787-.219-1.593-.253-2.397-.068-1.63-.057-3.26.019-4.889.028-.585.058-1.172.192-1.746.292-1.236.984-2.164 2.141-2.72.632-.304 1.306-.442 2.003-.5 1.843-.155 3.688-.087 5.532-.078.638.001 1.265.05 1.876.144l-.069-.009c1.109.17 2.095.588 2.83 1.475.396.478.681 1.062.809 1.703l.004.025c.218 1.063.215 2.145.237 3.223a54.22 54.22 0 01-.039 3.761c-.028.572-.047 1.147-.156 1.71-.304 1.585-1.184 2.694-2.726 3.244-.64.228-1.302.309-1.978.309zm.124-13.873c-.985 0-1.97-.008-2.955 0-.596.006-1.188.064-1.75.287-.64.251-1.094.687-1.327 1.344-.177.497-.2 1.016-.232 1.536-.074 1.198-.06 2.399-.056 3.599.004 1.039.007 2.079.154 3.11.098.687.403 1.263 1.004 1.649.418.268.888.384 1.373.458.459.067.92.058 1.38.068a100.3 100.3 0 005.346-.02 4.64 4.64 0 001.467-.257l-.033.01c.682-.243 1.164-.686 1.408-1.38.165-.467.196-.954.225-1.439.092-1.548.073-3.098.048-4.646-.013-.787-.017-1.578-.182-2.353-.202-.952-.806-1.521-1.728-1.78a5.633 5.633 0 00-1.452-.186l-.077.001h.004c-.873-.004-1.746 0-2.62 0z"
          ></path>
          <path
            fill="#272727"
            d="M12.246 16.422c0-1.678.966-3.11 2.462-3.698 1.728-.68 3.756.064 4.666 1.72.493.896.636 1.856.384 2.839-.348 1.35-1.181 2.294-2.513 2.752-1.972.676-4.053-.412-4.754-2.308a3.61 3.61 0 01-.174-.605l-.004-.025a2.767 2.767 0 01-.068-.616l.001-.063v.003zm6.016-.064c.008-1.231-.973-2.24-2.185-2.249-1.225-.008-2.221.995-2.226 2.24-.004 1.226.985 2.24 2.189 2.252 1.219.01 2.211-.991 2.219-2.241zm1.061-3.833v-.015c0-.484.39-.876.872-.881a.897.897 0 01.01 1.787h-.003a.879.879 0 01-.879-.879v-.012.001z"
          ></path>
        </svg>
      ),
      target: "https://instagram.com",
    },
    {
      src: (
        <svg role="img" title="Facebook" viewBox="0 0 32 32">
          <path
            fill="#fefefe"
            d="M16.064 1.856C8.203 1.852 1.837 8.217 1.837 16.08v.012c0 7.851 6.365 14.216 14.216 14.216h.012-.001c7.863 0 14.225-6.358 14.228-14.225S23.928 1.856 16.064 1.856z"
          ></path>
          <path
            fill="#fefefe"
            d="M16.064 1.856c7.863 0 14.228 6.368 14.225 14.229S23.925 30.31 16.064 30.307h-.012c-7.851 0-14.216-6.365-14.216-14.216v-.012.001c0-7.863 6.366-14.228 14.227-14.224z"
          ></path>
          <path
            fill="#272727"
            d="M17.261 20.608c0 1.386-.005 2.771.005 4.156 0 .227-.038.312-.291.307q-1.501-.025-3.008 0c-.244.004-.294-.064-.293-.3.009-2.76 0-5.52.013-8.28 0-.287-.072-.365-.353-.348-.403.026-.81 0-1.216.01-.161.003-.225-.039-.224-.213q.011-1.582 0-3.165c0-.18.069-.209.225-.207.426.008.852-.008 1.28.008.208.007.27-.052.272-.266.005-.723-.067-1.45.07-2.169.342-1.804 1.514-2.835 3.357-2.909.96-.038 1.92 0 2.877-.01.179 0 .21.07.209.227a163.94 163.94 0 000 2.624c0 .192-.068.227-.239.223a61.534 61.534 0 00-1.664 0c-.608.004-.877.27-.887.877-.006.384.013.768-.007 1.152-.012.229.084.256.278.256.778-.008 1.555 0 2.333-.007.184 0 .234.046.215.235a138.697 138.697 0 00-.287 3.086c-.018.23-.103.27-.31.266-.682-.013-1.364.005-2.048-.01-.24-.005-.323.045-.32.308.023 1.377.015 2.762.015 4.148z"
          ></path>
        </svg>
      ),
      target: "https://facebook.com",
    },
    {
      src: (
        <svg role="img" title="Linkedin" viewBox="0 0 32 32">
          <path
            fill="#fefefe"
            d="M15.94 1.856c-7.862 0-14.228 6.364-14.228 14.227v.008c0 7.852 6.365 14.217 14.217 14.217h.008c7.864 0 14.225-6.358 14.228-14.225S23.804 1.856 15.941 1.856z"
          ></path>
          <path
            fill="#272727"
            d="M15.566 22.496h-1.529c-.192-.02-.153-.166-.155-.287l-.022-1.8c.034-1.455.028-2.911 0-4.367.007-.889.02-1.779.015-2.668 0-.215.061-.276.273-.273.612.008 1.224-.007 1.836-.013h.28l.271.036V14.4l.298-.332c.536-.591 1.17-.995 1.979-1.111.527-.075 1.034.009 1.546.095 1.408.497 1.977 1.587 2.104 2.988l.012 4.303c-.006.611-.022 1.222-.012 1.832.004.237-.058.32-.305.314-.622-.013-1.245.004-1.868.01l-.463-.009a.08.08 0 00-.032-.059l-.008-2.083c0-1.121.015-2.24.004-3.361-.003-.308-.064-.62-.25-.884l-.081-.142v.003c-.565-.847-1.952-.845-2.569.004-.244.286-.334.64-.339.992-.017 1.128 0 2.257 0 3.385l-.008 2.083a.083.083 0 00-.032.059l-.53.01h-.209zm-3.329-2.08c-.005.555-.034 1.111-.006 1.664.017.341-.087.467-.42.408h-.014a.473.473 0 00-.126.016l.004-.001h-1.389l-.677-.01-.048-.058c-.005-.694.034-1.388-.02-2.082.008-.611.028-1.22.024-1.831-.006-.832.036-1.66-.024-2.488.008-.853.035-1.707.015-2.56-.007-.32.093-.384.388-.374.553.019 1.107-.004 1.661-.01.057.006.114.015.172.017.448.008.448.008.448.474.003.82.01 1.64.014 2.46v4.376zm-.077-8.822a2.166 2.166 0 01-.555.61l-.006.004c-.896.334-1.377.192-1.99-.595-.099-.349-.161-.696-.049-1.057.207-.668 1.068-1.317 2.102-.748.596.476.77 1.071.498 1.785z"
          ></path>
        </svg>
      ),
      target: "https://linkedin.com",
    },
    {
      src: (
        <svg role="img" title="Youtube" viewBox="0 0 32 32">
          <path
            fill="#fefefe"
            d="M16.315 1.856c-7.862 0-14.228 6.364-14.228 14.227v.012c0 7.851 6.365 14.216 14.216 14.216h.01-.001c7.863 0 14.225-6.358 14.228-14.225S24.178 1.857 16.315 1.857z"
          ></path>
          <path
            fill="#272727"
            d="M16.291 22.522c-1.959-.049-4.04-.019-6.114-.198-.645-.056-1.265-.196-1.757-.668-.461-.442-.635-1.02-.744-1.615-.163-.896-.205-1.807-.222-2.716-.027-1.513-.035-3.028.167-4.532.115-.855.32-1.684 1.113-2.21a2.487 2.487 0 011.249-.407h.006c3.561-.239 7.124-.226 10.688-.113.806.026 1.617.028 2.414.166.817.141 1.398.597 1.716 1.379.299.736.353 1.514.402 2.291.121 1.949.128 3.899-.11 5.839-.104.846-.333 1.664-1.116 2.18-.399.264-.842.373-1.312.408-2.083.157-4.172.168-6.38.196zm-1.979-9.193v5.462l5.252-2.723z"
          ></path>
        </svg>
      ),
      target: "https://youtube.com",
    },
    {
      src: (
        <svg role="img" title="Spotify" viewBox="0 0 32 32">
          <path
            fill="#272727"
            d="M15.901 2.328c-7.542 0-13.65 6.106-13.651 13.65v.01c0 7.533 6.106 13.639 13.639 13.639h.009-.001c7.544.003 13.648-6.099 13.651-13.647S23.444 2.329 15.9 2.328z"
          ></path>
          <path
            fill="#fff"
            d="M15.872 1.66c-7.889 0-14.285 6.396-14.285 14.285S7.984 30.23 15.872 30.23s14.285-6.396 14.285-14.285S23.763 1.66 15.872 1.66zm6.553 20.604a.89.89 0 01-1.228.294l.004.002c-3.355-2.048-7.577-2.513-12.55-1.377a.89.89 0 01-.401-1.734l.006-.001c5.44-1.244 10.112-.708 13.875 1.592a.89.89 0 01.292 1.228l.002-.004zm1.748-3.89a1.116 1.116 0 01-1.537.364l.005.003c-3.84-2.36-9.693-3.043-14.234-1.664a1.114 1.114 0 01-.656-2.129l.008-.002c5.188-1.574 11.638-.812 16.048 1.898a1.114 1.114 0 01.363 1.535l.003-.005zm.151-4.051c-4.605-2.734-12.2-2.986-16.596-1.651a1.337 1.337 0 01-.785-2.557l.009-.003c5.046-1.532 13.435-1.236 18.736 1.911a1.336 1.336 0 11-1.37 2.294l.006.003z"
          ></path>
        </svg>
      ),
      target: "https://spotify.com",
    },
  ];
  function getCurrentYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
  }
  return (
    // <div className="landing_footer">
    //   <Container fluid className="d-none d-lg-block">
    //     <Row className="d-flex justify-content-center align-items-center mx-2">
    //       <Col lg={12}>
    //         <p className="mb-0 landing_footer_text">support@nexteats.com</p>
    //       </Col>
    //       <Col lg={12}>
    //         <p className="mb-0 landing_footer_text">
    //           <a href="tel:+44 7794 048892">+44 7794 048892 </a>
    //         </p>
    //       </Col>
    //       <Col lg={12}>
    //         {" "}
    //         <p className="mb-0  cursor-pointer landing_footer_text">
    //           {" "}
    //           Contact Us
    //         </p>
    //       </Col>
    //     </Row>
    //     <Row className="d-flex justify-content-center align-items-center mx-2">
    //       <p className="landing_footer_text mb-0">
    //         Copyright <i className="fas fa-copyright"></i> {getCurrentYear()}{" "}
    //         NextEats. All rights reserved.
    //       </p>
    //     </Row>
    //   </Container>

    //   <Container className="d-block d-lg-none" fluid>
    //     <Row className="d-flex flex-column justify-content-center align-items-center">
    //       <p className="landing_footer_text">
    //         Copyright <i className="fas fa-copyright"></i> {getCurrentYear()}{" "}
    //         NextEats. All rights reserved.
    //       </p>
    //     </Row>
    //     <Row className="d-flex justify-content-between align-items-center">
    //       <Col className="d-none  d-sm-flex flex-column ">
    //         <p className="mb-0 landing_footer_text">support@nexteats.com</p>
    //         <p className="mb-0 landing_footer_text">
    //           <a href="tel:+44 7794 048892">+44 7794 048892</a>
    //         </p>
    //       </Col>
    //       <Col className="d-block d-sm-none">
    //         <p className="mb-0 landing_footer_text">Contact Us</p>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
    <div class="copyright-area">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                <div class="copyright-text">
                    <p>Copyright &copy; {getCurrentYear()}{" "} NextEats, All Right Reserved <a href="https://nexteats-staging.web.app/"><img src="https://res.cloudinary.com/dzhvjtoot/image/upload/v1712309249/logo/ogb3puh0buzqtzem3ce1.png" width={17} height={17}/></a></p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div class="footer-menu">
                    <ul>
                        <li> <a href="email: support@nexteats.com">support@nexteats.com</a></li>
                        <li><a href="#">Privacy</a></li>
                        <li><a href="#">Policy</a></li>
                        <li><a className="cursor-pointer" onClick={()=>history.push('/contact')}>Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
        </div>
  );
};

export default LandingFooter;
