import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  Label,
} from "reactstrap";
import Mpink from "../../assets/img/Mpink.png";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { adminSignUp } from "store/actions/authActions";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { createNewSubscription } from "store/actions/subscriptionAction";
import { RepositoryFactory } from "repository/RepositoryFactory";
import { checkAuthAndSendVerification } from "store/actions/authActions";
let Payment = RepositoryFactory.get("payment");
const SignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [error, setError] = useState({});

  const validateCredentials = () => {
    if (
      email.includes(" ") ||
      !/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password) ||
      password.length < 6 ||
      password.includes(" ") ||
      !/\d/.test(password)
    ) {
      if (
        !/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password) ||
        password.length < 6
      ) {
        setError({
          ...error,
          password:
            "Password must have a special character, a digit and it should be 6 digit long",
          email: "",
        });
      } else if (email.includes(" ")) {
        setError({
          ...error,
          email: "Invalid Password",
          password: "",
        });
      } else {
        setError({
          ...error,
          email: "",
          password: "",
        });
      }
      return false;
    } else {
      return true;
    }
  };

  const SignUpAction = async () => {
    setPaymentLoader(true);
    let restaurantID = "";
    let planId = "";
    await Payment.getPaymentPrices()
      .then((res) => {
        let { data } = res;
        data?.data?.forEach((record) => {
          let nickname = record?.nickname;
          if (nickname === subscriptionType) {
            planId = record?.id;
          }
        });
      })
      .catch((err) => {
        setPaymentLoader(false);
      });
    setPaymentLoader(false);

    let obj = {
      email,
      name,
      paymentMethod,
    };

    dispatch(
      adminSignUp(obj, password, async (data) => {
        restaurantID = data?.user?.uid;
        localStorage.setItem(
          "encryptedPassword",
          JSON.stringify({ email: obj.email, password: password })
        );
        if (paymentMethod === "easypaisa") {
          const userData = {
            uid: data?.user?.uid,
            email: data?.user?.email,
            restaurantName: name,
            paymentMethod,
            subscriptionType,
          };
          setEmail("");
          setSubscriptionType("");
          setPassword("");
          setPaymentMethod("");
          dispatch(
            checkAuthAndSendVerification({
              onSuccess: () => {
                history.push({
                  pathname: "/auth/easypaisa",
                  state: { userData },
                });
              },
            })
          );
        } else {
          let body = {
            plan: subscriptionType,
            restaurantID,
            priceId: planId,
          };
          dispatch(
            createNewSubscription(body, () => {
              setEmail("");
              setSubscriptionType("");
              setPassword("");
              setPaymentMethod("");
              setPaymentLoader(false);
            })
          );
        }
      })
    );
  };

  return (
    <Col lg="5" md="7" className="login">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 pt-lg-2 pb-lg-2">
          <div className=" d-flex flex-column justify-content-center align-items-center">
            <img
              src={Mpink}
              alt="logo"
              onClick={() => history.push("/")}
              className="mb-1 w-25"
            />
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3 className="title">Sign Up</h3>
            </div>
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (validateCredentials()) {
                SignUpAction();
              }
            }}
          >
            <Row className="py-2">
              <Col className="col-12 pr-sm-3">
                <FormGroup className="mb-2">
                  <Label className="form_label">Restaurant Name</Label>
                  <Input
                    placeholder="Marina Munch"
                    type="text"
                    autoComplete="new-name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="col-12 col-sm-6 pr-sm-2">
                <FormGroup className="mb-1">
                  <Label className="form_label">Merchant Email</Label>
                  <Input
                    placeholder="example@gmail.com"
                    type="email"
                    autoComplete="new-email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {error?.email ? (
                    <span className="text-danger" style={{ fontSize: "11px" }}>
                      {error.email}
                    </span>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>
              <Col className="col-12 col-sm-6 pl-sm-2">
                <FormGroup className="mb-1">
                  <Label className="form_label">Password</Label>
                  <div className="login__password">
                    <Input
                      placeholder="Password"
                      required
                      type={eye ? "text" : "password"}
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {eye ? (
                      <FaEyeSlash
                        className="eye_icon"
                        onClick={() => setEye(!eye)}
                      />
                    ) : (
                      <FaEye
                        className="eye_icon"
                        onClick={() => setEye(!eye)}
                      />
                    )}
                  </div>
                  {error?.password ? (
                    <span className="text-danger" style={{ fontSize: "11px" }}>
                      {error.password}
                    </span>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup className="mb-3">
              <Label className="form_label">Payment Method</Label>
              <Input
                type="select"
                required
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value="" disabled>
                  Select Payment Method
                </option>
                <option value="international">International Cards</option>
                <option value="easypaisa">Easypaisa</option>
              </Input>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form_label">Subscription Type</Label>
              <Input
                type="select"
                required
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
              >
                <option value="" disabled>
                  Select Subscription Type
                </option>
                <option value="monthly">Monthly $29.99</option>
                <option value="yearly">Yearly $300</option>
              </Input>
            </FormGroup>

            <Button
              className="mt-1 mb-1 w-100 login__btn"
              type="submit"
              disabled={
                !email ||
                !subscriptionType ||
                !password ||
                !subscriptionType ||
                paymentLoader
              }
              dispatch={subscriptionType}
            >
              {paymentLoader ? <Spinner size={"sm"} /> : "Sign Up"}
            </Button>

            <Row className="mt-0 mb-2 mx-2 justify-content-end">
              <Link to="/auth/login" className="pointer">
                <small>Already have an account? Login</small>
              </Link>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SignUp;
