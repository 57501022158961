import { toast } from 'react-toastify';
import firebase from '../../config/firebase';

export const addCatalog = (obj, onSuccess) => async dispatch => {
	dispatch(catalogLoader(true));
	firebase
		.firestore()
		.collection('catalogs')
		.add(obj)
		.then(() => {
			dispatch(catalogLoader(false));
			toast.success('Catalog Added Successfully');
			onSuccess();
		})
		.catch(err => {
			alert(err);
			dispatch(catalogLoader(false));
		});
};

export const getCatalogsByID = (ID, Count) => async dispatch => {
	dispatch(catalogLoader(true));
	firebase
		.firestore()
		.collection('catalogs')
		.where('restaurantID', '==', ID)
		.limit(Count)
		.onSnapshot(query => {
			let temp = [];
			query.docs.forEach(doc => {
				temp.push({
					id: doc.id,
					...doc.data(),
				});
			});
			dispatch({ type: 'GET_CATALOGS', payload: temp });
			// dispatch({ type: 'GET_CATALOGS_PAGES', payload: currentPage });
			dispatch(catalogLoader(false));
		});
};
export const getSearchedCatalogById = (ID, searchTerm) => async dispatch => {
	dispatch(catalogLoader(true));
	firebase
		.firestore()
		.collection('catalogs')
		.where('restaurantID', '==', ID)
		.where('category','==',searchTerm)
		.onSnapshot(query => {
			let temp = [];
			query.docs.forEach(doc => {
				temp.push({
					id: doc.id,
					...doc.data(),
				});
			});
			dispatch({ type: 'GET_CATALOGS', payload: temp });
			// dispatch({ type: 'GET_CATALOGS_PAGES', payload: currentPage });
			dispatch(catalogLoader(false));
		});
};
export const updateCatalog = (id, obj, onSuccess) => async dispatch => {
	dispatch(catalogLoader(true));
	const docRef = firebase.firestore().collection('catalogs').doc(id);
	docRef
		.set(obj)
		.then(() => {
			dispatch(catalogLoader(false));
			toast.success('Catalog Updated Successfully');
			onSuccess();
		})
		.catch(err => {
			alert(err);
			dispatch(catalogLoader(false));
		});
};
export const deleteCatalogsByID = (ID, onSuccess) => async dispatch => {
	dispatch(catalogLoader(true));
	firebase
		.firestore()
		.collection('catalogs')
		.doc(ID)
		.delete()
		.then(() => {
			dispatch(catalogLoader(false));
			toast.success('Catalog Deleted Successfully');
			onSuccess();
		})
		.catch(err => {
			alert(err);
			dispatch(catalogLoader(false));
		});
};

const catalogLoader = data => async dispatch => {
	dispatch({
		type: 'CATALOG_LOADER',
		payload: data,
	});
};
