import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { deleteQuestion } from "store/actions/checkoutQuestionAction";

const DeleteQuestionModal = ({ modal, toggle, id }) => {
  const dispatch = useDispatch();
  const { deleteLoading } = useSelector((state) => state.checkoutQuestion);
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Delete Question</ModalHeader>
      <ModalBody>
        Deleting Questions will result in deleting responses as well, are you
        sure you want to delete the question?
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          size="sm"
          disabled={deleteLoading}
          onClick={() =>
            dispatch(
              deleteQuestion(id, () => {
                toggle();
              })
            )
          }
        >
          {deleteLoading ? <Spinner size="sm" /> : "Delete"}
        </Button>{" "}
        <Button color="secondary" size="sm" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteQuestionModal;
