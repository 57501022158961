import React, { useState } from "react";
import { Table, Row, Button, Input, Tooltip, Spinner } from "reactstrap";
import fbvector from "../../assets/img/socialIcons/fbVector.svg";
import insta from "../../assets/img/socialIcons/insta.svg";
import twiterVector from "../../assets/img/socialIcons/twiterVector.svg";
import snapchat from "../../assets/img/socialIcons/snapchat.svg";
import discord from "../../assets/img/socialIcons/discord.svg";
import linkedIn from "../../assets/img/socialIcons/linkedinVector.svg";
import website from "../../assets/img/socialIcons/website.svg";
import linktree from "../../assets/img/socialIcons/linktree.svg";
import youtube from "../../assets/img/socialIcons/youtube.svg";
import tiktok from "../../assets/img/socialIcons/tiktok.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateSocialProfile } from "store/actions/settingAction";

const SocialProfileTable = ({ data }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const dispatch = useDispatch();
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [urlError, setUrlError] = useState("");

  const validateURL = (type, url) => {
    const regexMap = {
      facebook: /^https?:\/\/(www\.)?facebook\.com\/[A-Za-z0-9_.-]+\/?$/,
      twiter: /^https?:\/\/(www\.)?(x|twitter)\.com\/[A-Za-z0-9_]{1,15}\/?$/,
      instagram: /^https?:\/\/(www\.)?instagram\.com\/[A-Za-z0-9_.-]+\/?$/,
      tiktok: /^https?:\/\/(www\.)?tiktok\.com\/@[A-Za-z0-9_.-]+\/?$/,
      linkedIn: /^https?:\/\/(www\.)?linkedin\.com\/in\/[A-Za-z0-9_.-]+\/?$/,
      youtube: /^https?:\/\/(www\.)?youtube\.com\/[A-Za-z0-9_.-]+\/?$/,
      discord: /^https?:\/\/(www\.)?discord\.com\/[A-Za-z0-9_.-]+\/?$/,
      snapchat: /^https?:\/\/(www\.)?snapchat\.com\/add\/[A-Za-z0-9_.-]+\/?$/,
      linktree: /^https?:\/\/(www\.)?linktr\.ee\/[A-Za-z0-9_.-]+\/?$/,
      website:
        /^https?:\/\/(localhost|([A-Za-z0-9-]+\.)+[A-Za-z]{2,})(:[0-9]{1,5})?(\/.*)?$/,
    };

    const urlExamples = {
      facebook: "https://www.facebook.com/your-profile",
      twiter: "https://www.x|twitter.com/your-username",
      instagram: "https://www.instagram.com/your-profile",
      tiktok: "https://www.tiktok.com/@your-username",
      linkedIn: "https://www.linkedin.com/in/your-profile",
      youtube: "https://www.youtube.com/your-channel",
      discord: "https://www.discord.com/your-profile",
      snapchat: "https://www.snapchat.com/add/your-username",
      linktree: "https://www.linktr.ee/your-username",
      website: "https://your-website.com",
    };

    const platformRegex = regexMap[type];
    setUrlError("");

    if (platformRegex && platformRegex.test(url)) {
      return true;
    } else {
      setUrlError(
        type === "website"
          ? `Please enter a valid website URL (e.g., ${urlExamples[type]}).`
          : `Please enter a valid ${type} URL (e.g., ${urlExamples[type]}).`
      );
      return false;
    }
  };

  const updatedData = [
    { type: "facebook", icon: fbvector },
    { type: "instagram", icon: insta },
    { type: "tiktok", icon: tiktok },
    { type: "discord", icon: discord },
    { type: "youtube", icon: youtube },
    { type: "website", icon: website },
    { type: "linktree", icon: linktree },
    { type: "linkedIn", icon: linkedIn },
    { type: "snapchat", icon: snapchat },
    { type: "twiter", icon: twiterVector },
  ];

  const updatedSocialProfiles = data?.map((profile) => {
    const matchingIcon = updatedData.find((item) => item.type === profile.type);
    return matchingIcon
      ? { ...profile, icon: matchingIcon.icon, url: profile.url || "" }
      : profile;
  });

  const handleUpdate = (type, url) => {
    dispatch(updateSocialProfile(user?.restaurantID, type, url)).then(() => {
      setLoading(false);
      setEditIndex(-1);
    });
  };

  return (
    <>
      <Row className="py-4">
        <div className="col">
          <div className="d-flex justify-content-between py-3">
            <div className="d-flex align-items-center">
              <h3 className="pt-2">Social Profiles</h3>
            </div>
          </div>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Type</th>
                <th scope="col">URL</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {updatedSocialProfiles?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div
                      className="d-flex justify-content-center align-items-center p-2"
                      style={{
                        background: restaurantMedia?.adminColor || "#d70365",
                        width: "fit-content",
                        borderRadius: "100%",
                      }}
                    >
                      <img
                        src={item.icon}
                        alt="Social Link"
                        id={item.type}
                        className="img-fluid"
                      />
                    </div>
                  </td>
                  <td>
                    {index === editIndex ? (
                      <>
                        <Input
                          style={{
                            padding: ".5rem",
                            height: "auto",
                            width: "50%",
                          }}
                          type={item.type === "website" ? "url" : "text"}
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />
                        {urlError && editIndex === index && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {urlError}
                          </span>
                        )}
                      </>
                    ) : (
                      <span>
                        {item.url.length > 40
                          ? item.url.slice(0, 40) + "..."
                          : item.url}
                      </span>
                    )}
                  </td>
                  <td>
                    {index === editIndex ? (
                      <Button
                        size="sm"
                        color="success"
                        onClick={() => {
                          if (url && validateURL(item.type, url)) {
                            setLoading(true);
                            handleUpdate(item.type, url);
                          }
                        }}
                      >
                        {loading ? (
                          <Spinner size="sm" />
                        ) : item.url ? (
                          "Update"
                        ) : (
                          "Add"
                        )}
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => {
                          setUrlError("");
                          setEditIndex(index);
                          setUrl(item.url);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target={updatedSocialProfiles.map((item) => item.type).join(", ")}
              toggle={toggle}
            >
              {updatedSocialProfiles.map((item) => item.type).join(", ")}
            </Tooltip>
          </Table>
        </div>
      </Row>
    </>
  );
};

export default SocialProfileTable;
