/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)

// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts

// reactstrap components

// core components
import { chartOptions, parseOptions } from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { getRestaurantStats } from "store/actions/statsActions";
import { useDispatch } from "react-redux";
import { getTopMerchants } from "store/actions/statsActions";
import { getTopProducts } from "store/actions/statsActions";
import { getOrderAnalysis } from "store/actions/statsActions";

const Index = () => {
  const dispatch = useDispatch();
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  useEffect(() => {
    dispatch(getRestaurantStats());
    dispatch(getTopMerchants());
    dispatch(getTopProducts());
    dispatch(getOrderAnalysis());
  }, []);
  return (
    <>
      <Header />
    </>
  );
};

export default Index;
