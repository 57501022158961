import firebase from "../../config/firebase";
import algoliasearch from "algoliasearch";
import { createNullCache } from "@algolia/cache-common";
import { toast } from "react-toastify";
const client = algoliasearch("GPYJEAVUBP", "b1f999bc5b264defe930762391d240c6", {
  responsesCache: createNullCache(),
});
export const getRequests =
  (search, id, hitsPerPage, currentPage, startDate, endDate) =>
  async (dispatch) => {
    try {
      console.log("getRequest data>>>>>>>>>>>>>>>");
      dispatch(orderLoader(true));
      const index = client.initIndex("requests");
      console.log(index, "requestsindex>>>>>>>");
      index
        .search(search, {
          filters: `restaurantID:${id} AND (createdAt._seconds >= ${startDate} AND createdAt._seconds <= ${endDate})AND status:processing`,
          hitsPerPage: hitsPerPage,
          page: currentPage,
        })
        .then((response) => {
          console.log(response, "Order Requests");
          let { hits, ...rest } = response;
          dispatch({
            type: "REQUESTS",
            payload: hits,
          });
          dispatch({ type: "GET_ALL_REQUESTS", payload: rest });
        });
    } catch (err) {
      alert(err.message);
    }
  };

export const updateRequest = (id, status) => async (dispatch) => {
  firebase.firestore().collection("requests").doc(id).update({
    status: status,
  });
  dispatch({
    type: "UPDATE_REQUESTS",
    payload: id,
  });
};

export const getAllOrders =
  (
    search,
    id,
    currentPage,
    filter,
    startWeek,
    endWeek,
    startDate,
    endDate,
    minValue,
    maxValue
  ) =>
  async (dispatch) => {
    dispatch(orderLoader(true));
    const orderIndex = client.initIndex("orders");

    let amountFilter = "";
    if (minValue !== undefined && maxValue !== undefined) {
      amountFilter = ` AND (totalAmount >= ${minValue} AND totalAmount <= ${maxValue})`;
    } else if (minValue !== undefined) {
      amountFilter = ` AND totalAmount >= ${minValue}`;
    } else if (maxValue !== undefined) {
      amountFilter = ` AND totalAmount <= ${maxValue}`;
    }

    if (filter == "All") {
      orderIndex
        .search(search, {
          filters: `restaurantID:${id} AND (createdAt._seconds > ${startDate} AND createdAt._seconds < ${endDate})${amountFilter}`,
          page: currentPage,
        })
        .then(async (response) => {
          let { hits, ...rest } = response;
          // Sort the hits array by createdAt field in descending order
          hits.sort((a, b) => b.createdAt._seconds - a.createdAt._seconds);
          await dispatch({
            type: "ORDERS",
            payload: hits,
          });
          dispatch({ type: "ORDERS_DETAILS", payload: rest });
          dispatch(orderLoader(false));
        });
    } else if (filter == "New") {
      orderIndex
        .search(search, {
          filters: `restaurantID:${id} AND (createdAt._seconds < ${startWeek} AND createdAt._seconds > ${endWeek})${amountFilter}`,
          page: currentPage,
        })
        .then(async (response) => {
          let { hits, ...rest } = response;
          // Sort the hits array by createdAt field in descending order
          hits.sort((a, b) => b.createdAt._seconds - a.createdAt._seconds);
          await dispatch({
            type: "ORDERS",
            payload: hits,
          });
          dispatch({ type: "ORDERS_DETAILS", payload: rest });
          dispatch(orderLoader(false));
        });
    } else {
      orderIndex
        .search(search, {
          filters: `restaurantID:${id} AND status:${filter}${amountFilter}`,
          page: currentPage,
        })
        .then(async (response) => {
          let { hits, ...rest } = response;
          // Sort the hits array by createdAt field in descending order
          hits.sort((a, b) => b.createdAt._seconds - a.createdAt._seconds);
          await dispatch({
            type: "ORDERS",
            payload: hits,
          });
          dispatch({ type: "ORDERS_DETAILS", payload: rest });
          dispatch(orderLoader(false));
        });
    }
  };

export const updateOrder = (item, status) => async (dispatch) => {
  try {
    await firebase.firestore().collection("orders").doc(item?.id).update({
      status: status,
    });
    dispatch({
      type: "UPDATE_ORDER_STATUS",
      payload: {
        id: item?.id,
        status: status,
      },
    });
    if (item?.orderType === "delivery" && item?.driverId) {
      try {
        await firebase
          .firestore()
          .collection("users")
          .doc(item?.driverId)
          .update({
            isAssigned: false,
          });
      } catch (driverUpdateError) {
        console.log("Driver update failed:", driverUpdateError.message);
      }
    }
  } catch (error) {
    console.log("Order update failed:", error.message);
  }
};

// Notfications

export const getUnreadNotfications = (id) => async (dispatch) => {
  if (id) {
    firebase
      .firestore()
      .collection("notifications")
      .where("restaurantID", "==", id)
      .where("markAsRead", "==", false)
      .orderBy("createdAt", "desc")
      .onSnapshot((query) => {
        let unread = [];
        for (let doc of query.docs) {
          unread.push({ id: doc.id, ...doc.data() });
        }
        dispatch({
          type: "UNREAD_NOTFICATIONS",
          payload: unread,
        });
      });
  } else {
    firebase
      .firestore()
      .collection("notifications")
      .where("markAsRead", "==", false)
      .onSnapshot((query) => {
        let unread = [];
        for (let doc of query.docs) {
          unread.push({ id: doc.id, ...doc.data() });
        }
        dispatch({
          type: "UNREAD_NOTFICATIONS",
          payload: unread,
        });
      });
  }
};

export const updateNotification = (id) => async (dispatch) => {
  firebase.firestore().collection("notifications").doc(id).update({
    markAsRead: true,
  });
};

export const clearAllNotifications = (id) => async (dispatch) => {
  try {
    const notificationsRef = firebase.firestore().collection("notifications");

    const querySnapshot = await notificationsRef
      .where("restaurantID", "==", id)
      .get();

    const batch = firebase.firestore().batch();

    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        batch.delete(doc.ref);
      }
    });

    await batch.commit();

    toast.success("All Notifications Cleared");
    // onSuccess();
  } catch (error) {
    console.error("Error clearing notifications:", error);
    toast.error("Error clearing notifications");
  }
};

export const getNotifications = (id) => async (dispatch) => {
  firebase
    .firestore()
    .collection("notifications")
    .where("restaurantID", "==", id)
    .limit(15)
    .orderBy("createdAt", "desc")
    .onSnapshot((query) => {
      let notifications = [];
      for (let doc of query.docs) {
        notifications.push({ id: doc.id, ...doc.data() });
      }
      dispatch({
        type: "ALL_NOTIFICATIONS",
        payload: notifications,
      });
    });
};

export const getNextNotifications =
  (id, object, onSuccess = () => {}) =>
  async (dispatch) => {
    await firebase
      .firestore()
      .collection("notifications")
      .where("restaurantID", "==", id)
      .limit(15)
      .orderBy("createdAt", "desc")
      .startAfter(object?.createdAt)
      .onSnapshot((query) => {
        let notifications = [];
        for (let doc of query.docs) {
          notifications.push({ id: doc.id, ...doc.data() });
        }
        onSuccess();
        dispatch({
          type: "ALL_NOTIFICATIONS",
          payload: notifications,
        });
      });
  };

export const orderLoader = (val) => async (dispatch) => {
  dispatch({
    type: "ORDER_LOADER",
    payload: val,
  });
};

export const getSubscriptionRequests =
  ({ onComplete = () => {} }) =>
  async (dispatch) => {
    try {
      const query = firebase
        .firestore()
        .collection("subscriptions")
        .orderBy("createdAt", "desc")
        .limit(5);
      query.onSnapshot((snapshot) => {
        const subscriptionRequests = snapshot.docs.map((doc) => doc.data());
        dispatch({
          type: "GET_SUBSCRIPTION_REQUESTS",
          payload: subscriptionRequests,
        });
        onComplete();
      });
    } catch (error) {
      console.error(error.message || "An unknown error has occurred.");
      toast.error(error.message || "An unknown error has occurred.");
      onComplete();
    }
  };

export const acceptSubscriptionRequest =
  ({
    id,
    subscriptionType,
    imageUrl,
    startDate = "",
    onSuccess = () => {},
    onError = () => {},
  }) =>
  async () => {
    try {
      const today = firebase.firestore.Timestamp.now();
      let endDate = null;
      if (subscriptionType === "monthly") {
        endDate = firebase.firestore.Timestamp.fromDate(
          new Date(today.toDate().setMonth(today.toDate().getMonth() + 1))
        );
      } else if (subscriptionType === "yearly") {
        endDate = firebase.firestore.Timestamp.fromDate(
          new Date(today.toDate().setFullYear(today.toDate().getFullYear() + 1))
        );
      }

      await firebase
        .firestore()
        .collection("users")
        .doc(id)
        .update({
          easypaisaSubscription: {
            status: "active",
            type: subscriptionType,
            startDate: startDate ? startDate : today,
            endDate,
            isDowngraded: false,
          },
          subScriptionStatus: "subscribe",
        });

      await firebase.firestore().collection("subscriptions").doc(id).delete();
      if (imageUrl) {
        const storageRef = firebase.storage().refFromURL(imageUrl);
        await storageRef.delete();
      }

      toast.success("Subscription accepted successfully.");
      onSuccess();
    } catch (error) {
      console.error(error.message || "An unknown error has occurred.");
      toast.error(error.message || "An unknown error has occurred.");
      onError();
    }
  };

export const downgradeSubscription =
  ({ id, onSuccess = () => {}, onError = () => {} }) =>
  async () => {
    try {
      await firebase.firestore().collection("users").doc(id).update({
        "easypaisaSubscription.isDowngraded": true,
      });

      await firebase.firestore().collection("subscriptions").doc(id).delete();
      toast.success("Subscription downgraded successfully.");
      onSuccess();
    } catch (error) {
      console.error(error.message || "An unknown error has occurred.");
      toast.error(error.message || "An unknown error has occurred.");
      onError();
    }
  };

export const rejectSubscriptionRequest =
  ({ id, imageUrl, onSuccess = () => {}, onError = () => {} }) =>
  async () => {
    try {
      await firebase.firestore().collection("subscriptions").doc(id).delete();
      if (imageUrl) {
        const storageRef = firebase.storage().refFromURL(imageUrl);
        await storageRef.delete();
      }
      toast.success("Subscription request rejected successfully.");
      onSuccess();
    } catch (error) {
      console.error(error.message || "An unknown error has occurred.");
      toast.error(error.message || "An unknown error has occurred.");
      onError();
    }
  };

export const cancelEasypaisaSubscription =
  ({ id, onSuccess = () => {}, onError = () => {} }) =>
  async () => {
    try {
      await firebase.firestore().collection("users").doc(id).update({
        easypaisaSubscription: firebase.firestore.FieldValue.delete(),
        subScriptionStatus: "unsubscribe",
      });

      toast.success("Subscription cancelled successfully.");
      onSuccess();
    } catch (error) {
      console.error(error.message || "An unknown error has occurred.");
      toast.error(error.message || "An unknown error has occurred.");
      onError();
    }
  };

export const getEasypaisaSubscriptionStatus =
  ({ id, onSuccess = () => {}, onError = () => {} }) =>
  async () => {
    try {
      const doc = await firebase
        .firestore()
        .collection("subscriptions")
        .doc(id)
        .get();
      const exists = doc.exists;
      if (exists) {
        onSuccess(true);
      } else {
        onSuccess(false);
      }
    } catch (error) {
      console.error(error.message || "An unknown error has occurred.");
      toast.error(error.message || "An unknown error has occurred.");
      onError();
      return false;
    }
  };
