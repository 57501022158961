import React, {useState } from "react";
import * as htmlToImage from "html-to-image";
import { Button, Container, Spinner } from "reactstrap";
import download from "downloadjs";
import { useDispatch, useSelector } from "react-redux";
import { addBackground } from "store/actions/restaurantAction";
import { toast } from "react-toastify";
import ColorPicker from "./ColorPicker";
import SelectModeDDM from "./SelectModeDDM";

// function importAll(r) {
//   let images = [];
//   r.keys().map((item, index) => {
//     images.push(r(item));
//   });
//   return images;
// }

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// function dataURItoBlob(dataURI) {
//   // convert base64 to raw binary data held in a string
//   // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
//   var byteString = atob(dataURI.split(",")[1]);

//   // separate out the mime component
//   var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

//   // write the bytes of the string to an ArrayBuffer
//   var ab = new ArrayBuffer(byteString.length);
//   var ia = new Uint8Array(ab);
//   for (var i = 0; i < byteString.length; i++) {
//     ia[i] = byteString.charCodeAt(i);
//   }

//   //Old Code
//   //write the ArrayBuffer to a blob, and you're done
//   //var bb = new BlobBuilder();
//   //bb.append(ab);
//   //return bb.getBlob(mimeString);

//   //New Code
//   return new Blob([ab], { type: mimeString });
// }

const BackgroundGeneration = ({ selectedImages, setSelectedImages }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [colorSelect, setColorSelect] = useState(false);
  const [iconsColor, setIconsColor] = useState("#ffffff");
  const [mode, setMode] = useState("Select Theme");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  // const images = importAll(
  //   require.context("../assets/svg", false, /\.(png|jpe?g|svg)$/)
  // );

  const getImagesPatternArr = (images) => {
    let patternArrLength = 400;
    let patternArr = [];
    for (let i = 0; i < Math.ceil(patternArrLength / images?.length); i++) {
      for (let j = 0; j < images?.length; j++) {
        patternArr.push(images[j]);
      }
    }
    return patternArr;
  };

  const handleSaveImage = async () => {
    if (mode !== "Select Theme") {
      setLoader(true);

      try {
        let node = document.getElementById("image");
        let canvas = document?.createElement("canvas");
        let context = canvas?.getContext("2d");
        let dataUrl = await htmlToImage.toPng(node);
        const image = new Image();
        image.src = dataUrl;
        image.addEventListener("load", () => {
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            image.width,
            image.height
          );

          canvas?.toBlob(async (blob) => {
            try {
              let restaurantID = user?.restaurantID;
              let file = new File([blob], `${restaurantID}_${mode}_BG.png`, {
                type: "image/png",
                lastModified: new Date().getTime(),
              });

              await dispatch(addBackground(restaurantID, mode, file));
              setLoader(false);
              download(file, "pattern.png", "image/png");
              toast.success("Image saved successfully");
              setSelectedImages([]);
            } catch (error) {
              console.error("Error in dispatch:", error);
              setLoader(false);
              toast.error("Error saving image");
            }
          });
        });
      } catch (error) {
        console.error("Error in image generation:", error.message);
        setLoader(false);
        toast.error("Error generating image");
      }
    } else {
      toast.error("No theme selected");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end image-container mb-3">
        <SelectModeDDM
          dropdownOpen={dropdownOpen}
          toggle={toggle}
          mode={mode}
          setMode={setMode}
        />
        <Button
          size="sm"
          color="primary"
          onClick={() => setColorSelect((previous) => !previous)}
        >
          Pick a color
        </Button>
        <Button
          className="mr-3"
          size="sm"
          color="primary"
          onClick={handleSaveImage}
          // onClick={() => {
          //   if (mode != "Select Theme") {
          //     setLoader(true);
          //     let node = document?.getElementById("image");
          //     let canvas = document?.createElement("canvas");
          //     let context = canvas?.getContext("2d");
          //     htmlToImage
          //       .toPng(node)
          //       .then(function (dataUrl) {
          //         const image = new Image();
          //         image.src = dataUrl;
          //         image.addEventListener("load", () => {
          //           canvas.width = image.width;
          //           canvas.height = image.height;
          //           context.drawImage(
          //             image,
          //             0,
          //             0,
          //             image.width,
          //             image.height,
          //             0,
          //             0,
          //             image.width,
          //             image.height
          //           );
          //           canvas?.toBlob((blob) => {
          //             let restaurantID = user?.restaurantID;
          //             let file = new File(
          //               [blob],
          //               `${restaurantID}_${mode}_BG.png`,
          //               {
          //                 type: "image/png",
          //                 lastModified: new Date().getTime(),
          //               }
          //             );
          //             dispatch(
          //               addBackground(restaurantID, mode, file, () => {
          //                 setLoader(false);
          //                 download(file, "pattern.png", "image/png");
          //                 toast.success("Image saved successfully");
          //                 setSelectedImages([]);
          //               })
          //             );
          //           });
          //         });
          //       })
          //       .catch(function (error) {
          //         console.error("oops, something went wrong!", error);
          //       });
          //   } else {
          //     toast.error("No theme selected");
          //   }
          // }}
        >
          {loader ? <Spinner size="sm" /> : "Save Image"}
        </Button>
      </div>
      <div
        className="d-flex flex-wrap justify-content-between image-container"
        style={{
          backgroundColor: mode !== "dark" ? "transparent" : "#393f42",
          marginLeft: 0,
          marginRight: 0,
        }}
        id="image"
      >
        {getImagesPatternArr(selectedImages).map((image, index) => {
          let size = randomIntFromInterval(20, 50);
          let rotate = randomIntFromInterval(0, 1.5) >= 0.5;
          let rotateDeg = randomIntFromInterval(-360, 360);
          let style = {
            marginLeft: randomIntFromInterval(10, 25),
            marginRight: randomIntFromInterval(10, 25),
            marginTop: randomIntFromInterval(0, 15),
            opacity: 0.1,
          };
          return (
            <div
              key={index}
              style={{
                width: `${size}px`,
                height: `${size}px`,
                transform: `${rotate ? `rotate(${rotateDeg}deg)` : ""}`,
                fill: `${
                  mode === "dark"
                    ? iconsColor
                    : iconsColor !== "#ffffff"
                    ? iconsColor
                    : "#CACACA"
                }`,
                ...style,
              }}
            >
              {image}
            </div>
          );
        })}
      </div>
      {colorSelect && (
        <Container>
          <ColorPicker
            iconsColor={iconsColor}
            setIconsColor={setIconsColor}
            setColorSelect={setColorSelect}
          />
        </Container>
      )}
    </>
  );
};

export default BackgroundGeneration;
