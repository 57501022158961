import React from 'react'
import { useState,useEffect } from 'react';
import {Row,Col,ListGroup,Spinner,ListGroupItem,UncontrolledCollapse,Card,CardBody,Container,CardHeader} from 'reactstrap'
import { getFaqs } from 'store/actions/faqsActions';
import { useSelector,useDispatch } from 'react-redux';
function Faqs() {
  const dispatch=useDispatch();
  const {faqs,faqsLoader}=useSelector((state)=>state.faqs);
    const [isOpen, setIsOpen] = useState(Array(Questions.length).fill(false));
  
    const handleQuestionOpen = (index) => {
      const updatedIsOpen = [...isOpen];
      updatedIsOpen[index] = !updatedIsOpen[index];
      setIsOpen(updatedIsOpen);
    };
    useEffect(()=>{
     dispatch(getFaqs());
    },[])
    return (
        <div>
             <Container className="mt-8 mb-6" fluid>
        <Row >
          <Col>
            <Card className="shadow">
              <CardHeader className="">
                <h3 className="mb-0 text-danger">FAQ'S & Guides</h3>
              </CardHeader>
              <CardBody>
              <Row>
                      <Col xs="12">
                      {faqsLoader? (
          <div className="d-flex justify-content-center align-items-center py-5">
            {" "}
            <Spinner size="md" color="danger" />
          </div>
        ) : faqs?.length > 0 ? faqs?.map((q, index) => (
                          <ListGroup key={index}>
                            <ListGroupItem
                              className="bg-danger mb-1 px-3 py-2"
                              id={`faq${index + 1}`}
                              tag="button"
                              onClick={() => handleQuestionOpen(index)}
                            >
                              <Row className="justify-content-between align-items-center">
                                <Col xs="10" className="text-left">
                                  <span className="mr-2 text-white">{`${index + 1}. ${
                                    q?.question
                                  }`}</span>
                                </Col>

                                <Col className="text-right">
                                  <i
                                    className={`fas ${
                                      isOpen[index]
                                        ? "fa-angle-down"
                                        : "fa-angle-right"
                                    } text-white`}
                                  />
                                </Col>
                              </Row>
                            </ListGroupItem>
                            <UncontrolledCollapse
                              toggler={`#faq${index + 1}`}
                              className="mb-2"
                            >
                              <Card>
                                <CardBody className="px-3 py-2">
                                  {q?.answer}
                                </CardBody>
                              </Card>
                            </UncontrolledCollapse>
                          </ListGroup>
                        )):<div className="d-flex justify-content-center align-items-center py-5 text-danger">FAQ's Not Found</div>}
                      </Col>
                    </Row>
              </CardBody>
              </Card>
             </Col>
             </Row></Container>
        </div>
    )
}

export default Faqs;
const Questions = [
    {
      question: "What is the capital of France?",
      answer: "The capital of France is Paris.",
    },
    {
      question: "Who is the author of 'To Kill a Mockingbird'?",
      answer: "Harper Lee is the author of 'To Kill a Mockingbird.'",
    },
    {
      question: "What is the largest mammal on Earth?",
      answer: "The blue whale is the largest mammal on Earth.",
    },
    {
      question: "In which year did the first moon landing occur?",
      answer: "The first moon landing occurred in 1969.",
    },
    {
      question: "What is the chemical symbol for gold?",
      answer: "The chemical symbol for gold is Au.",
    },
  ];