import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { db } from "../../config/firebase";
import { RepositoryFactory } from "repository/RepositoryFactory";
let User = RepositoryFactory.get("user");
let Payment = RepositoryFactory.get("payment");

// export const unsubscribe =
//   (sub_id, uid, onSuccess, onError) => async (dispatch) => {
//     await User.unSubscribe(sub_id)
//       .then(async (response) => {
//         await firebase
//           .firestore()
//           .collection("users")
//           .doc(uid)
//           .update({ subScriptionStatus: "unsubscribe" })
//           .then(
//             onSuccess(),
//             toast.success("Subscription Cancelled Successfuly.!")
//           );
//       })
//       .catch((ex) => {
//         //   alert(ex);
//         toast.error(ex);
//         onError();
//       });
//   };
export const unsubscribe =
  (sub_id, uid, onSuccess, onError) => async (dispatch) => {
    console.log(sub_id, "sub_id >>>>>>>>>>");
    try {
      await User.unSubscribe(sub_id);
      const collections = [
        "requests",
        "checkoutQuestions",
        "categories",
        "notifications",
        "labels",
        "menus",
        "parentMenus",
        "orders",
        "visits",
      ];

      const deletePromises = collections.map((collection) =>
        firebase
          .firestore()
          .collection(collection)
          .where("restaurantID", "==", uid)
          .get()
          .then((querySnapshot) => {
            const deletePromises = querySnapshot.docs.map((doc) => {
              if (collection == "menus") {
                let images = doc?.data()?.images;
                if (images && images?.length > 0) {
                  for (var image of images) {
                    const fileName = image.split("/").pop().split("?")[0];
                    const storageRef = firebase.storage().ref().child(fileName);
                    storageRef.delete();
                  }
                  doc.ref.delete();
                }
              } else if (collection == "categories") {
                let image = doc.data()?.imageURL;
                if (image) {
                  let imagePath = image.split("?")[0];
                  let storageRef = firebase.storage().refFromURL(imagePath);

                  storageRef.delete();
                }
                doc.ref.delete();
              } else if (collection == "labels") {
                let image = doc.data()?.labelURL;
                if (image) {
                  let imagePath = image.split("?")[0];
                  let storageRef = firebase.storage().refFromURL(imagePath);
                  storageRef.delete();
                }
                doc.ref.delete();
              } else if (collection == "checkoutQuestions") {
                const questions = [];
                questions.push({
                  id: doc.id,
                  ...doc.data(),
                });
                deleteCheckoutResponses(questions)
                  .then(() => {
                    doc.ref.delete();
                    // dispatch()
                  })
                  .catch((error) => {
                    console.error("Error occurred during deletion:", error);
                  });
              }
            });
            return Promise.all(deletePromises);
          })
      );

      await Promise.all(deletePromises);

      const usersRef = firebase.firestore().collection("users");
      const usersSnapshot = await usersRef
        .where("restaurantID", "==", uid)
        .get();

      const deleteUsersPromises = usersSnapshot.docs
        .filter((doc) => doc.id !== uid)
        .map((doc) => doc.ref.delete());

      await Promise.all(deleteUsersPromises);
      const userDoc = await usersRef.doc(uid).get();
      const userData = userDoc.data();
      const currentSubscription = userData.subscription || {};
      console.log(currentSubscription, "currentSubscription>>>>>>>");
      await usersRef.doc(uid).update({
        subScriptionStatus: "unsubscribe",
        subscription: { ...currentSubscription, subscriptionID: "" },
      });

      onSuccess();
      toast.success("Subscription Cancelled Successfully!");
      dispatch({
        type: "UPDATE_SUBSCRIPTION_STATUS",
        payload: "unsubscribe",
      });
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
      onError();
    }
  };
export const upgradeSubscription =
  (currentSubscription, uid, setLoader, updateToggle) => async (dispatch) => {
    try {
      const previousSubscription =
        currentSubscription?.subscriptionPlan?.charAt(0) +
        currentSubscription?.subscriptionPlan?.slice(1);
      const res = await Payment.getPaymentPrices();
      const data = res?.data?.data || [];
      // console.log(data,"data");
      for (const record of data) {
        const nickName = record?.nickname;
        console.log(nickName,"nickName>>>>>>");
        const subscription = nickName?.split(" ");
        const newSubscription = subscription[0]?.toLowerCase();
        console.log(newSubscription,"nickName>>>>>>");
        if (newSubscription !== previousSubscription) {
          const planId = record?.id;
          const body = {
            plan: newSubscription,
            priceId: planId,
            subscriptionId: currentSubscription?.subscriptionID,
          };

          const response = await User.updateSubscription(body);
          const payload = {
            customerID: response?.data?.data?.subscription?.customer,
            subscriptionID: response?.data?.data?.subscription?.id,
            subscriptionPlan: newSubscription,
          };
          await firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .update({ subscription: payload })
            .then(() => {
              toast.success("Subscription Updated Successfully.!");
              updateToggle();
              setLoader(false);
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      }
    } catch (error) {
      console.error("Error upgrading subscription:", error);
      toast.error("Failed to update subscription");
      setLoader(false);
    }
  };
async function deleteCheckoutResponses(questions) {
  for (var question of questions) {
    const querySnapshot = await firebase
      .firestore()
      .collection("checkoutResponses")
      .where("questionId", "==", question.id)
      .get();

    querySnapshot.forEach((doc) => {
      doc.ref.delete();
    });
  }
}

export const createSubscribe =
  (obj, uid, onSuccess, onError) => async (dispatch) => {
    console.log(obj);
    await User.createSubscription(obj)
      .then(async (response) => {
        let payload = {
          customerID: response.data.data.customer.id,
          subscriptionID: response.data.data.subscription.id,
          subscriptionPlan: obj.plan,
        };
        await firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .update({ subscription: payload, subScriptionStatus: "subscribe" })
          .then(
            onSuccess(),
            toast.success("Re-Subscribe Successfuly.!"),
            dispatch({
              type: "UPDATE_SUBSCRIPTION_STATUS",
              payload: "subscribe",
            }),
            dispatch({
              type: "SETCOUPON",
              payload: {},
            })
          );
      })
      .catch((ex) => {
        toast.error(ex.message);
        console.log(ex.message);
        onError();
      });
  };

export const createNewSubscription =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log(payload, "payload");
    await User.createNewSubscription(payload).then(async (response) => {
      console.log(response);
      let { data } = response;
      console.log(data.data, "data");
      window.location.href = data?.data;
      onSuccess();
    });
  };
export const AppyCoupon = (payload) => async (dispatch) => {
  console.log(payload, "payload");
  dispatch(couponLoader(true));
  await User.CouponVerification(payload)
    .then(async (response) => {
      console.log(response);
      let { data } = response;
      dispatch({
        type: "SETCOUPON",
        payload: data,
      });
      dispatch(couponLoader(false));
    })
    .catch((error) => {
      toast.error("Invalid Coupon!");
      dispatch(couponLoader(false));
    });
};
const couponLoader = (data) => async (dispatch) => {
  dispatch({
    type: "COUPON_LOADER",
    payload: data,
  });
};
