
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { getTutorials } from "store/actions/tutorialsActions";
import { useDispatch, useSelector } from "react-redux";
const LandingTutorials = () => {
  const dispatch = useDispatch();
  const { loading, tutorials } = useSelector((state) => state.tutorials);
  const [searchTerm, setSearchTerm] = useState("");
  const [menuData, setMenuData] = useState(tutorials);
  const [entries, setEntries] = useState(undefined);
  useEffect(() => {
    dispatch(getTutorials(entries));
  }, [entries]);
  function searchTutorials(searchTerm) {
    const foundtutorials = tutorials.filter(
      (f) =>
        (f.title && f.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (f.text && f.text.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    return foundtutorials;
  }

  const handleChange = (value) => {
    setSearchTerm(value);
  };
  useEffect(() => {
    if (searchTerm !== "") {
      const filteredtutorials = searchTutorials(searchTerm);
      setMenuData(filteredtutorials);
    } else {
      setMenuData(tutorials);
    }
  }, [searchTerm, tutorials]);

  return (
    <>
      <section className="mt-6 mb-3">
        <div className="container-fluid">
          <Row>
            <div className="col">
              <Card className="shadow w-100">
                <CardHeader className="d-lg-flex justify-content-between">
                  <h3>Tutorials</h3>
                  <Row>
                    <Col xs="12" sm="4" lg="7">
                      <Input
                        placeholder="Search here..."
                        value={searchTerm}
                        onChange={(e) => handleChange(e.target.value)}
                      />
                    </Col>
                    <Col xs="12" sm="4" lg="5" className="my-2 my-sm-0">
                      {" "}
                      <Input
                        type="select"
                        id="exampleCustomSelect"
                        name="customSelect"
                        value={entries}
                        onChange={(e) => {
                          setEntries(e.target.value);
                        }}
                      >
                        <option value={undefined}>All Entries</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </Input>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center py-5">
                      {" "}
                      <Spinner size="md" color="danger" />
                    </div>
                  ) : menuData?.length > 0 ? (
                    <Row className="my-3 justify-content-center">
                      {menuData?.map((tut, indx) => {
                        return (
                          <Col
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={10}
                            className="tutorials mb-4"
                            key={indx}
                          >
                            <Card className="tutorials__card">
                              <video
                                controls
                                muted
                                className="tutorials__card-media"
                              >
                                <source src={tut?.video} type="video/mp4" />
                              </video>
                              <CardBody>
                                <CardTitle tag="h5">
                                  {indx + 1}. {tut?.title}
                                </CardTitle>
                                <CardText>{tut?.text}</CardText>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center p-6 nodata">
                      <p className="text-danger"> No Tutorials Found</p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
      </section>
    </>
  );
};

export default LandingTutorials;
