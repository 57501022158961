import { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

import OnlyHeader from "components/Headers/OnlyHeader.js";
import GenderDistributionModal from "components/Modals/GenderDistributionModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "store/actions/customerAction";
import RangeSlider from "../components/slider/RangeSlider";

const Customers = () => {
  const dispatch = useDispatch();
  const { user, userPermissions} = useSelector((state) => state.auth);
  const { customerData, customerRestData } = useSelector(
    (state) => state.customer
  );
  const [value, setValue] = useState([0, 100]);
  const [gender, setGender] = useState("");
  const [genderModal, setGenderModal] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [hitsPerPage, setHitsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const customerPermissions = userPermissions?.customers;

  const genderToggle = () => {
    setGenderModal(!genderModal);
  };

  const renderCustomers = () => {
    const startIndex = currentPage * hitsPerPage;
    const endIndex = startIndex + hitsPerPage;
    const customersToShow = customerData?.slice(startIndex, endIndex);

    return customersToShow !== 0 ? (
      customersToShow?.map((customer, index) => (
        <tr key={index}>
          <th scope="row">{customer?.name}</th>
          <td>{customer?.age}</td>
          <td>{customer?.gender}</td>
          <td>{customer?.email}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={4} className="text-center">
          No Customer Found.
        </td>
      </tr>
    );
  };

  useEffect(() => {
    dispatch(getAllCustomers(user?.restaurantID, searchField, gender, value, hitsPerPage));
  }, [searchField, hitsPerPage, gender, value]);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = customerRestData?.nbPages;
    if (totalPages >= 1) {
      const visiblePages = 3;
      let startPage = Math.max(currentPage - 1, 0);
      let endPage = Math.min(startPage + visiblePages - 1, totalPages - 1);

      if (startPage > 0) {
        pageNumbers.push(
          <PaginationItem key="start-ellipsis" disabled>
            <PaginationLink href="">...</PaginationLink>
          </PaginationItem>
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink href="" onClick={() => handleClick(i)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        );
      }

      if (endPage < totalPages - 1) {
        if (endPage < totalPages - 2) {
          pageNumbers.push(
            <PaginationItem key="end-ellipsis" disabled>
              <PaginationLink href="">...</PaginationLink>
            </PaginationItem>
          );
        }

        pageNumbers.push(
          <PaginationItem key={totalPages - 1}>
            <PaginationLink href="" onClick={() => handleClick(totalPages - 1)}>
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    return pageNumbers;
  };
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7 mb-5" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <Row>
                  <Col sm="6">
                    {" "}
                    <h3>Customers</h3>
                  </Col>
                  <Col sm="6" className="d-flex justify-content-sm-end">
                    <Button
                      color="primary"
                      onClick={genderToggle}
                      disabled={customerData?.length === 0}
                    >
                      {customerData?.length !== 0
                        ? "Customer Demographics"
                        : "No Customer Available"}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col sm="6" lg="3" className="mt-3">
                    <Input
                      placeholder="Search here..."
                      onChange={(e) => {
                        setCurrentPage(0);
                        setSearchField(e.target.value);
                      }}
                      value={searchField}
                    />
                  </Col>
                  <Col sm="6" lg="3" className="mt-3">
                    {" "}
                    <Input
                      className=" "
                      type="select"
                      id="exampleCustomSelect"
                      value={gender}
                      onChange={(e) => {
                        setGender(e.target.value);
                        setCurrentPage(0);
                      }}
                      name="gender"
                    >
                      <option defaultValue={""} disabled>
                        Select Gender
                      </option>
                      <option value="all">All</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Input>
                  </Col>
                  <Col sm="6" lg="3" className="mt-3">
                    <Input
                      type="select"
                      id="exampleCustomSelect"
                      name="customSelect"
                      value={hitsPerPage}
                      onChange={(e) => {
                        setHitsPerPage(e.target.value);
                        setCurrentPage(0);
                      }}
                    >
                      <option value="5">Show Entries</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </Input>
                  </Col>
                  <Col className="text-center mt-3" sm="6" lg="3">
                    {" "}
                    <RangeSlider
                      value={value}
                      setValue={setValue}
                      setCurrentPage={setCurrentPage}
                    />
                    <span className="text-nowrap text-center">Age Range</span>
                  </Col>
                </Row>
              </CardHeader>
              {user?.type === "kitchen-admin" ? (
                <>
                  {customerPermissions?.get ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Age</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Email</th>
                        </tr>
                      </thead>
                      <tbody>{renderCustomers()}</tbody>
                    </Table>
                  ) : (
                    <Row className="py-4 justify-content-center align-items-center">
                      You don't have the permission to access the page
                    </Row>
                  )}
                </>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Age</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>{renderCustomers()}</tbody>
                </Table>
              )}
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage === 0}>
                      <PaginationLink
                        href=""
                        onClick={() => handleClick(currentPage - 1)}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {/* Page numbers */}
                    {renderPageNumbers()}
                    {/* Next button */}
                    <PaginationItem
                      disabled={currentPage === customerRestData?.nbPages - 1}
                    >
                      <PaginationLink
                        href=""
                        onClick={() => handleClick(currentPage + 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <GenderDistributionModal
        genderModal={genderModal}
        genderToggle={genderToggle}
      />
    </>
  );
};

export default Customers;
