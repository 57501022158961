import OnlyHeader from "components/Headers/OnlyHeader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Row, Container, Card, CardHeader, Col, Button } from "reactstrap";

function VenueSettingMenu() {
  const { user, userPermissions } = useSelector((state) => state.auth);
  const settingsPermissions = userPermissions?.settings;

  const data = [
    {
      icon: "fas fa-business-time",
      title: "Store Timings",
      link: "store-timings",
    },
    {
      icon: "fas fa-truck",
      title: "Pickup or Delivery",
      link: "pickup-or-delivery",
    },
    {
      icon: "fas fa-user-clock",
      title: "Special Holiday Schedule",
      link: "holidays",
    },
    {
      icon: "fas fa-user-clock",
      title: "Restaurant Language",
      link: "venue-restaurant-language",
    },
    {
      icon: "fas fa-user-clock",
      title: "Restaurant Currency",
      link: "venue-restaurant-currency",
    },
  ];
  const history = useHistory();
  return (
    <>
      <OnlyHeader />
      <Container fluid className="mt--7">
        <div className="col">
          <Card className="shadow">
            <CardHeader className="d-lg-flex d-sm-block justify-content-between">
              <div className="d-flex align-items-center">
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    history.push("/admin/settings");
                  }}
                >
                  <i className="fas fa-arrow-left "></i>
                </Button>

                <h3 className=" pt-2 ">Venue Setting</h3>
              </div>
            </CardHeader>

            <Container className="my-4">
              {user?.type === "kitchen-admin" ? (
                <>
                  {settingsPermissions?.get ? (
                    <Row className="pb-2">
                      {data?.map((el, id) => {
                        return (
                          <Col
                            // md={2}
                            key={id}
                            className="mt-2 d-flex justify-content-center"
                          >
                            <Card
                              className="shadow rounded d-flex justify-content-center align-items-center py-4 px-2 pointer"
                              style={{
                                width: "150px",
                              }}
                              onClick={() => {
                                history.push(`/admin/${el.link}`);
                              }}
                            >
                              <i className={`${el.icon} fa-2x `}></i>
                              <h4 className="mt-2 m-0 text-center">
                                {el.title}
                              </h4>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <Row className="py-4 justify-content-center align-items-center">
                      You don't have the permission to access the page
                    </Row>
                  )}
                </>
              ) : (
                <Row className="pb-2">
                  {data?.map((el, id) => {
                    return (
                      <Col
                        key={id}
                        className="mt-2 d-flex justify-content-center"
                      >
                        <Card
                          className="shadow rounded d-flex justify-content-center align-items-center py-4 px-2 pointer"
                          style={{ width: "150px" }}
                          onClick={() => {
                            history.push(`/admin/${el.link}`);
                          }}
                        >
                          <i className={`${el.icon} fa-2x `}></i>
                          <h4 className="mt-2 m-0 text-center">{el.title}</h4>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Container>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default VenueSettingMenu;
