const initState = {
	loading: false,
	currencyLoading: false,
	paymentConnectionLoading: false,
	forwardFee: [],
	storeTimings: [],
};
const settingReducer = (state = initState, action) => {
	switch (action.type) {
		case "SET_LOADER":
			return {
				...state,
				loading: action.payload,
			};
		case "PAYMENT_CONNECTION_LOADER":
			return {
				...state,
				paymentConnectionLoading: action.payload,
			};
		case "SET_CURRENCY_LOADER":
			return {
				...state,
				currencyLoading: action.payload,
			};
		case "GET_FORWARD_FEE":
			return {
				...state,
				forwardFee: action.payload,
			};

		case "FETCH_STORE_TIMINGS":
			return {
				...state,
				storeTimings: action.payload,
			};

		case "UPDATE_STORE_TIMING":
			return {
				...state,
				storeTiming: action.payload,
			};

		default:
			return {
				...state,
			};
	}
};
export default settingReducer;
