import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const CheckoutQuestionsList = ({ checkoutOptions, setCheckoutOptions }) => {
  return (
    <div className="mx-4">
      <FormGroup check>
        <Input
          id="1"
          type="checkbox"
          checked={checkoutOptions.get}
          value={checkoutOptions?.get}
          onChange={(e) =>
            setCheckoutOptions({ ...checkoutOptions, get: e.target.checked })
          }
        />
        <Label check>Get</Label>
      </FormGroup>
      <FormGroup check>
        <Input
          id="2"
          type="checkbox"
          checked={checkoutOptions.add}
          value={checkoutOptions?.add}
          onChange={(e) =>
            setCheckoutOptions({ ...checkoutOptions, add: e.target.checked })
          }
        />
        <Label check>Add</Label>
      </FormGroup>
      <FormGroup check>
        <Input
          id="3"
          type="checkbox"
          checked={checkoutOptions.update}
          value={checkoutOptions?.update}
          onChange={(e) =>
            setCheckoutOptions({ ...checkoutOptions, update: e.target.checked })
          }
        />
        <Label check>Update</Label>
      </FormGroup>
    </div>
  );
};

export default CheckoutQuestionsList;
