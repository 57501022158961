import OnlyHeader from "components/Headers/OnlyHeader";
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap";
import Customize from "./Customize";

const Customization = () => {
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader className="d-lg-flex d-sm-block justify-content-between">
            <Row className="mx-2">
              <h3>Customization</h3>
            </Row>
          </CardHeader>
          <CardBody>
            <Customize />
              </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Customization;
