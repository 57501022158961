import EasypaisaPayment from "components/EasypaisaPayment";
import Stripe from "components/Stripe";
import React from "react";
import { useState, useEffect } from "react";
import { LuX } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { easypaisaSubscription } from "store/actions/authActions";
import { getEasypaisaSubscriptionStatus } from "store/actions/RequestActions";
import { cancelEasypaisaSubscription } from "store/actions/RequestActions";
import { upgradeSubscription } from "store/actions/subscriptionAction";
import { unsubscribe } from "store/actions/subscriptionAction";
import { AppyCoupon } from "store/actions/subscriptionAction";

const SubscriptionTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, uid } = useSelector((state) => state.auth);
  const { couponData } = useSelector((state) => state.customer);
  const { loading } = useSelector((state) => state.subscription);

  const [subscriptionSection, setSubscriptionSection] = useState(false);
  const [loader, setLoader] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponid, setCouponId] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [validCoupon, setvalidCoupon] = useState(false);
  const [amount, setAmount] = useState(0);

  const [statusLoader, setStatusLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [file, setFile] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [easypaisaLoader, setEasypaisaLoader] = useState(false);
  const [requestPending, setRequestPending] = useState(false);

  const toggle = () => setModal(!modal);
  const updateToggle = () => {
    if (updateModal && file) {
      setFile(null);
    }
    setUpdateModal(!updateModal);
  };

  const subscriptionSectionToggler = () => {
    if (subscriptionSection) {
      setFile(null);
      setPaymentMethod("");
      setSubscriptionType("");
    }
    setSubscriptionSection(!subscriptionSection);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setFile(file);
      } else {
        toast.error("Please select an image file (e.g., .jpg, .png)");
      }
    }
  };

  const Apply = async () => {
    let updatedAmount = amount;
    if (coupon) {
      if (subscriptionType === "monthly") {
        updatedAmount = 29.99;
      }
      if (subscriptionType === "yearly") {
        updatedAmount = 300;
      }
      const obj = {
        coupon,
        amount: updatedAmount,
      };
      await dispatch(AppyCoupon(obj));
      setAmount(updatedAmount);
    }
  };

  const handleUpgradeSubscription = () => {
    setLoader(true);
    dispatch(
      upgradeSubscription(user?.subscription, uid, setLoader, updateToggle)
    );
  };

  const handleUnsubscribe = () => {
    setLoader(true);
    dispatch(
      unsubscribe(
        user?.subscription?.subscriptionID,
        uid,
        () => {
          setLoader(false);
          toggle();
          history.push("/admin/subscriptions");
        },
        () => {
          setLoader(false);
        }
      )
    );
  };

  const handleEasypaisaSubscription = (e) => {
    e.preventDefault();
    let reSubscription = false;
    if (subscriptionSection && paymentMethod === "easypaisa") {
      reSubscription = true;
    }
    const userData = {
      uid,
      email: user?.email,
      restaurantName: user?.name,
      paymentMethod: "easypaisa",
      subscriptionType: !reSubscription
        ? user?.easypaisaSubscription?.type === "monthly"
          ? "yearly"
          : "monthly"
        : subscriptionType,
    };
    setEasypaisaLoader(true);
    dispatch(
      easypaisaSubscription({
        userData,
        paymentImage: file,
        request: !reSubscription
          ? user?.easypaisaSubscription?.type === "monthly"
            ? "upgrade"
            : "downgrade"
          : "subscription",
        startDate: !reSubscription
          ? user?.easypaisaSubscription?.startDate
          : "",
        onSuccess: () => {
          setEasypaisaLoader(false);
          if (!reSubscription) {
            updateToggle();
          } else {
            setRequestPending(true);
            subscriptionSectionToggler();
          }
        },
        onError: () => setEasypaisaLoader(false),
      })
    );
  };

  const handleEasypaisaUnsubscribe = () => {
    setEasypaisaLoader(true);
    dispatch(
      cancelEasypaisaSubscription({
        id: uid,
        onSuccess: () => {
          setEasypaisaLoader(false);
          setPaymentType("");
          toggle();
        },
        onError: () => setEasypaisaLoader(false),
      })
    );
  };

  useEffect(() => {
    if (couponData?.success) {
      setvalidCoupon(couponData?.data?.valid || false);
      setDiscountedAmount(couponData?.data?.discountedAmount || 0);
      setCouponId(couponData?.data?.coupon?.id || "");
    }
  }, [couponData]);

  useEffect(() => {
    if (user?.subScriptionStatus === "subscribe") {
      if (
        user?.easypaisaSubscription &&
        user?.easypaisaSubscription?.status === "active"
      ) {
        setPaymentType("easypaisa");
      } else {
        setPaymentType("");
      }
    } else {
      setPaymentType("");
    }
  }, [user]);

  useEffect(() => {
    setStatusLoader(true);
    dispatch(
      getEasypaisaSubscriptionStatus({
        id: uid,
        onSuccess: (isPending) => {
          setStatusLoader(false);
          setRequestPending(isPending);
        },
        onError: () => setStatusLoader(false),
      })
    );
  }, [user]);

  return (
    <>
      <div
        className={`${
          location.pathname === "/admin/subscriptions" &&
          "card shadow px-4 py-3"
        } mb-3`}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="pt-2">Subscription</h3>
          {subscriptionSection && (
            <Button
              size="sm"
              color="danger"
              onClick={subscriptionSectionToggler}
            >
              Cancel
            </Button>
          )}
        </div>
        {statusLoader ? (
          <div className="d-flex justify-content-center py-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            {user?.subScriptionStatus === "unsubscribe" &&
              !subscriptionSection && (
                <>
                  {requestPending ? (
                    <h1 className="mt-3">
                      Your subscription request is currently under review.
                    </h1>
                  ) : (
                    <Button
                      className="subscriptionBtn btn btn-success text-dark mt-3"
                      disabled={subscriptionSection}
                      onClick={subscriptionSectionToggler}
                    >
                      Re-Subscribe
                    </Button>
                  )}
                </>
              )}
            {user?.subScriptionStatus === "subscribe" && (
              <>
                {user?.easypaisaSubscription?.status === "active" ? (
                  <>
                    <h1 className="mt-3">
                      You are currently subscribed to{" "}
                      {user?.easypaisaSubscription?.type === "monthly"
                        ? "Monthly Plan ($29.99)"
                        : "Yearly Plan ($300)"}
                    </h1>
                    {user?.easypaisaSubscription?.isDowngraded && (
                      <h3>
                        Your subscription plan will be downgraded from{" "}
                        {user?.easypaisaSubscription?.endDate
                          .toDate()
                          .toLocaleDateString()}
                        .
                      </h3>
                    )}
                  </>
                ) : (
                  user?.subscription && (
                    <h1 className="mt-3">
                      You are currently subscribed to{" "}
                      {user?.subscription?.subscriptionPlan === "monthly"
                        ? "Monthly Plan ($29.99)"
                        : "Yearly Plan ($300)"}
                    </h1>
                  )
                )}
                <Row className="mb-3 mx-0">
                  <Button
                    className="btn btn-danger text-white mt-3"
                    onClick={toggle}
                  >
                    Unsubscribe
                  </Button>
                  {!user?.easypaisaSubscription?.isDowngraded && (
                    <Button
                      className="btn text-white mt-3"
                      color={
                        paymentType === "easypaisa"
                          ? user?.easypaisaSubscription?.type === "monthly"
                            ? "success"
                            : "warning"
                          : user?.subscription?.subscriptionPlan === "monthly"
                          ? "success"
                          : "warning"
                      }
                      onClick={updateToggle}
                    >
                      {paymentType === "easypaisa"
                        ? user?.easypaisaSubscription?.type === "monthly"
                          ? "Upgrade Subscription"
                          : "Downgrade Subscription"
                        : user?.subscription?.subscriptionPlan === "monthly"
                        ? "Upgrade Subscription"
                        : "Downgrade Subscription"}
                    </Button>
                  )}
                </Row>
              </>
            )}
            {subscriptionSection && (
              <div className="my-3">
                <FormGroup className="mb-3">
                  <Label className="form_label">Payment Method</Label>
                  <Input
                    type="select"
                    required
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Payment Method
                    </option>
                    <option value="international">International Cards</option>
                    <option value="easypaisa">Easypaisa</option>
                  </Input>
                </FormGroup>
                {paymentMethod && (
                  <div>
                    {paymentMethod === "easypaisa" ? (
                      <>
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Subscription Type
                          </Label>
                          <Input
                            className="form-control"
                            type="select"
                            value={subscriptionType}
                            onChange={(e) =>
                              setSubscriptionType(e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Subscription Type
                            </option>
                            <option value="monthly">Monthly</option>
                            <option value="yearly">Yearly</option>
                          </Input>
                        </FormGroup>
                        <EasypaisaPayment
                          card={false}
                          file={file}
                          handleFileChange={handleFileChange}
                          loading={easypaisaLoader}
                          handleSubmit={handleEasypaisaSubscription}
                          disabled={!subscriptionType}
                        />
                      </>
                    ) : (
                      <>
                        {location.pathname === "/admin/subscriptions" ? (
                          <div className="my-3">
                            <Row className="mb-3">
                              <Col md="8" lg="9">
                                <FormGroup className="mb-0">
                                  <Label className="form-label">
                                    Coupon Code
                                  </Label>
                                  <Input
                                    className="form-control"
                                    placeholder="Enter Coupon Code"
                                    type="text"
                                    value={coupon}
                                    onChange={(e) => setCoupon(e.target.value)}
                                  ></Input>
                                </FormGroup>
                              </Col>
                              <Col md="4" lg="3" className="mt-2">
                                <Button
                                  className="w-100 btn btn-danger text-white mt-4"
                                  disabled={
                                    coupon === "" ||
                                    subscriptionType === "" ||
                                    loading
                                  }
                                  onClick={(e) => Apply()}
                                >
                                  {loading ? <Spinner size="sm" /> : "Apply"}
                                </Button>
                              </Col>
                            </Row>
                            <FormGroup className="mb-0">
                              <Label className="form-label">
                                Subscription Type
                              </Label>
                              <Input
                                className="form-control"
                                type="select"
                                value={subscriptionType}
                                onChange={(e) =>
                                  setSubscriptionType(e.target.value)
                                }
                              >
                                <option value="" disabled>
                                  Subscription Type
                                </option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                              </Input>
                            </FormGroup>
                            <div className="mt-3">
                              <Stripe
                                subscriptionType={subscriptionType}
                                setSubscriptionType={setSubscriptionType}
                                subscriptionSectionToggler={
                                  subscriptionSectionToggler
                                }
                                couponid={couponid}
                                validCoupon={validCoupon}
                                discountedAmount={discountedAmount}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="my-3">
                            <FormGroup className="mb-0">
                              <Label className="form-label">
                                Subscription Type
                              </Label>
                              <Input
                                className="form-control"
                                type="select"
                                value={subscriptionType}
                                onChange={(e) =>
                                  setSubscriptionType(e.target.value)
                                }
                              >
                                <option value="">--select--</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                              </Input>
                            </FormGroup>
                            <div className="mt-3">
                              <Stripe
                                subscriptionType={subscriptionType}
                                setSubscriptionType={setSubscriptionType}
                                subscriptionSectionToggler={
                                  subscriptionSectionToggler
                                }
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Unsubscribe</ModalHeader>
        <ModalBody>
          Are you Sure?
          <br />
          Unsubscribing a restaurant may cause deleting all of the restaurant's
          data.
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            size="sm"
            onClick={
              paymentType === "easypaisa"
                ? handleEasypaisaUnsubscribe
                : handleUnsubscribe
            }
            disabled={loader || easypaisaLoader}
          >
            {loader || easypaisaLoader ? (
              <Spinner size={"sm"}></Spinner>
            ) : (
              "Unsubscribe"
            )}
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Modal isOpen={updateModal} toggle={updateToggle}>
        {paymentType === "easypaisa" &&
        user?.easypaisaSubscription?.type === "monthly" ? (
          <EasypaisaPayment
            file={file}
            handleFileChange={handleFileChange}
            loading={easypaisaLoader}
            handleSubmit={handleEasypaisaSubscription}
          />
        ) : (
          <>
            <ModalHeader toggle={updateToggle}>Update Subscripton</ModalHeader>
            <ModalBody>
              Are you Sure?
              <br />
              You want to{" "}
              {user?.subscription?.subscriptionPlan === "monthly"
                ? "Update"
                : "Downgrade"}{" "}
              your subscription
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                size="sm"
                onClick={
                  paymentType === "easypaisa"
                    ? handleEasypaisaSubscription
                    : handleUpgradeSubscription
                }
                disabled={loader || easypaisaLoader}
              >
                {loader || easypaisaLoader ? (
                  <Spinner size={"sm"}></Spinner>
                ) : paymentType === "easypaisa" ? (
                  "Send Request"
                ) : (
                  "Update"
                )}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};

export default SubscriptionTable;
