import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyBsU82SYMsr15kjb-hW_yd7r-Zh1phjSYQ",
  authDomain: "menux1.firebaseapp.com",
  projectId: "menux1",
  storageBucket: "menux1.appspot.com",
  messagingSenderId: "178222815343",
  appId: "1:178222815343:web:b7da0f0cf0a5760b39159a",
  measurementId: "G-X3SCK6P8YJ",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
export default firebase;
