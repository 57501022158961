import CustomizationTable from "components/Customization/CustomizationTable";
import OnlyHeader from "components/Headers/OnlyHeader";
import AddRestaurantImageModal from "components/Modals/AddRestaurantImageModal";
import DeleteRestaurantImageModal from "components/Modals/DeleteRestaurantImageModal";
import EditRestaurantImageModal from "components/Modals/EditRestaurantImageModal";
import defultLogo from "../../src/assets/img/Mpink.png";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import ImageGenerator from "./ImageGenerator";
import CustomerBG from "components/CustomerBG";

const Customize = () => {
  const { user, uid, userPermissions } = useSelector((state) => state.auth);
  const { restaurantMedia } = useSelector((state) => state.restaurant);

  const [galleryImages, setGalleryImages] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editData, setEditData] = useState("");
  const [index, setIndex] = useState("");

  const customizationPermissions = userPermissions?.customization;

  const addToggle = () => {
    setAddModal(!addModal);
    setGalleryImages([]);
  };
  const editToggle = () => {
    setEditModal(!editModal);
  };
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };

  let data = restaurantMedia?.images ? restaurantMedia?.images : [];

  let logo = restaurantMedia.logo ? restaurantMedia.logo : defultLogo;

  return (
    <>
      <CustomizationTable
        data={data}
        logo={logo}
        addToggle={addToggle}
        editToggle={editToggle}
        deleteToggle={deleteToggle}
        setEditData={setEditData}
        setIndex={setIndex}
      />

      {/* </Container> */}
      <AddRestaurantImageModal
        addModal={addModal}
        addToggle={addToggle}
        data={data}
        galleryImages={galleryImages}
        setGalleryImages={setGalleryImages}
      />

      <EditRestaurantImageModal
        editModal={editModal}
        editToggle={editToggle}
        editData={editData}
        setEditData={setEditData}
        index={index}
      />
      <DeleteRestaurantImageModal
        deleteModal={deleteModal}
        deleteToggle={deleteToggle}
        editData={editData}
        id={user?.restaurantID}
        title={"Image"}
      />
    </>
  );
};

export default Customize;
