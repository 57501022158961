import OnlyHeader from "components/Headers/OnlyHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Row,
  Container,
  Card,
  CardHeader,
  Col,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { updateDeliveryDays } from "store/actions/settingAction";
import { addDeliveryDays } from "store/actions/settingAction";
import { updatePickUpDays } from "store/actions/settingAction";
import { addPickUpDays } from "store/actions/settingAction";
function PickupOrDelivery() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.auth);
  const { loading,deliveryLoading } = useSelector((state) => state.catalog);
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const [pickupData, setPickUpData] = useState({
    startTime: "",
    endTime: "",
  });
  const [deliveryData, setDeliveryData] = useState({
    startTime: "",
    endTime: "",
  });
  const [selectedPickUpDays, setSelectedPickUpDays] = useState([]);
  const [selectedDeliveryDays, setSelectedDeliveryDays] = useState([]);

  const handlePickUpChange = (e) => {
    setPickUpData({ ...pickupData, [e.target.name]: e.target.value });
  };

  const handleDeliveryChange = (e) => {
    setDeliveryData({ ...deliveryData, [e.target.name]: e.target.value });
  };
  console.log(selectedDeliveryDays,selectedPickUpDays,"days");

  function handlePickUpDayClick(day) {
    setSelectedPickUpDays((prevSelectedDays) => {
      if (prevSelectedDays.includes(day)) {
        return prevSelectedDays.filter((d) => d !== day);
      } else {
        return [...prevSelectedDays, day];
      }
    });
  }
  function handleDeliveryDayClick(day) {
    setSelectedDeliveryDays((prevSelectedDays) => {
      if (prevSelectedDays.includes(day)) {
        return prevSelectedDays.filter((d) => d !== day);
      } else {
        return [...prevSelectedDays, day];
      }
    });
  }

  useEffect(() => {
    if (user?.pickupDays) {
      let temp = [];
      for (let i = 0; i < user?.pickupDays.length; i++) {
        temp.push(user?.pickupDays[i].day);
        setSelectedPickUpDays(temp);
      }
      setPickUpData({
        startTime: user?.pickupDays[0]?.startTime,
        endTime: user?.pickupDays[0]?.endTime,
      });
    }

    if (user?.deliveryDays) {
      let temp = [];
      for (let i = 0; i < user?.deliveryDays.length; i++) {
        temp.push(user?.deliveryDays[i].day);
        setSelectedDeliveryDays(temp);
      }
      setDeliveryData({
        startTime: user?.deliveryDays[0]?.startTime,
        endTime: user?.deliveryDays[0]?.endTime,
      });
    }
  }, [user]);

  const handleDelivery = () => {
    if (deliveryData?.startTime === deliveryData?.endTime) {
      alert("Delivery start and end time can not be the same");
      return;
    }
    if (deliveryData.startTime !== "" && deliveryData.endTime !== "") {
      if (user?.deliveryDays) {
        dispatch(updateDeliveryDays(uid, selectedDeliveryDays, deliveryData));
      } else {
        dispatch(addDeliveryDays(uid, selectedDeliveryDays, deliveryData));
      }
    }
  };
  const handlePickUp = () => {
    if (pickupData?.startTime === pickupData?.endTime) {
      alert("Pickup start and end time can not be the same");
      return;
    }
    if (pickupData.startTime !== "" && pickupData.endTime !== "") {
      if (user?.pickupDays) {
        dispatch(updatePickUpDays(uid, selectedPickUpDays, pickupData));
      } else {
        dispatch(addPickUpDays(uid, selectedPickUpDays, pickupData));
      }
    }
  };
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <>
      <OnlyHeader />
      <Container fluid className="mt--7">
        <div className="col">
          <Card className="shadow">
            <CardHeader className="d-lg-flex d-sm-block justify-content-between">
              <div className="d-flex align-items-center">
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    history.push("/admin/venue-setting-menu");
                  }}
                >
                  <i className="fas fa-arrow-left "></i>
                </Button>

                <h3 className=" pt-2 ">Pickup or Table Service</h3>
              </div>
            </CardHeader>

            <Container className="mb-4">
              <Label className="mt-3 ml-3">
                <strong>Pickup :</strong>
              </Label>
              <Row className="mx-3 my-3">
                <Col className="px-0">
                  <Label>Pickup Days</Label>
                  <Row className="mx-3">
                    {days?.map((day, idx) => {
                      return (
                        <Button
                          key={idx}
                          size="sm"
                          className="text-capitalize"
                          style={{
                            background: `${
                              selectedPickUpDays.includes(day)
                                ? restaurantMedia?.adminColor
                                : "#d1d5db"
                            }`,
                            color: `${
                              selectedPickUpDays.includes(day)
                                ? "#ffffff"
                                : "#000000"
                            }`,
                          }}
                          onClick={() => handlePickUpDayClick(day)}
                          // onClick={() => setPickUpActive(idx)}
                        >
                          {day}
                        </Button>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <Row className="mx-1 my-3">
                <Col>
                  <Label>Start Time</Label>
                  <Input
                    type="time"
                    name="startTime"
                    id="date-inp"
                    required
                    placeholder="Start time"
                    value={pickupData.startTime}
                    onChange={handlePickUpChange}
                  />
                </Col>
                <Col>
                  <Label>End Time</Label>
                  <Input
                    type="time"
                    name="endTime"
                    className="clickable-input"
                    id="date-inp"
                    placeholder="End time"
                    onChange={handlePickUpChange}
                    value={pickupData.endTime}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex mt-3">
                  {" "}
                  <Button
                    color="primary"
                    disabled={loading}
                    className="ml-auto"
                    onClick={handlePickUp}
                  >
                    {loading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </Col>
              </Row>
              <Label className="mt-3 ml-3">
                <strong>Delivery :</strong>
              </Label>
              <Row className="mx-3 my-3">
                <Col className="px-0">
                  <Label>Delivery Days</Label>
                  <Row className="mx-3">
                    {days?.map((day, idx) => {
                      return (
                        <Button
                          size="sm"
                          key={idx}
                          className="text-capitalize"
                          style={{
                            background: `${
                              selectedDeliveryDays.includes(day)
                                ? restaurantMedia?.adminColor
                                : "#d1d5db"
                            }`,
                            color: `${
                              selectedDeliveryDays.includes(day)
                                ? "#ffffff"
                                : "#000000"
                            }`,
                          }}
                          onClick={() => handleDeliveryDayClick(day)}
                        >
                          {day}
                        </Button>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <Row className="mx-1 my-3">
                <Col>
                  <Label>Start Time</Label>
                  <Input
                    type="time"
                    name="startTime"
                    id="date-inp"
                    placeholder="Start time"
                    onChange={handleDeliveryChange}
                    value={deliveryData.startTime}
                  />
                </Col>
                <Col>
                  <Label>End Time</Label>
                  <Input
                    type="time"
                    name="endTime"
                    id="date-inp"
                    placeholder="End time"
                    onChange={handleDeliveryChange}
                    value={deliveryData.endTime}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex mt-3">
                  {" "}
                  <Button
                    color="primary"
                    disabled={deliveryLoading}
                    className="ml-auto"
                    onClick={handleDelivery}
                  >
                    {deliveryLoading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card>
        </div>
      </Container>
    </>
  );
}
export default PickupOrDelivery;
