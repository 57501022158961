import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  Table,
  Row,
  Button,
  Input,
  Col,
} from "reactstrap";
import { getCatalogsByID,getSearchedCatalogById } from "store/actions/catalogAction";
function CatalogTable({
  // data,
  addToggle,
  editToggle,
  deleteToggle,
  setEditData,
  setId,
}) {
  const { user, userPermissions } = useSelector((state) => state.auth);
  const { catalogs } = useSelector((state) => state.catalog);
  const [searchTerm, setSearchTerm] = useState('');
  const [Count, setCount] = useState(5);
  useEffect(() => {
    dispatch(getCatalogsByID(user?.restaurantID,Count));
  }, [Count]);
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const handleSearch = (searchTerm) => {
    if(searchTerm===''){
      dispatch(getCatalogsByID(user?.restaurantID,Count));
    }
    else{
    dispatch(getSearchedCatalogById(user?.restaurantID,searchTerm));
    }
  };
  const debouncedSearch = debounce(handleSearch, 300); // Adjust delay as needed (e.g., 300ms)
  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);// Trigger debounced search
  };
      
  const dispatch = useDispatch();
  const catalogPermissions = userPermissions?.catalog;
  const returnDate = (startTime) => {
    if (startTime) {
      const date = new Date(`1970-01-01T${startTime}:00`);
      return date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
    return "";
  };

  return (
    <Row>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="d-lg-flex justify-content-between">
            <h3>Catalog</h3>
            <Row>
              <Col xs="12" sm="4" lg="5">
                <Input placeholder="Search Category..."  value={searchTerm}
  onChange={handleChange} />
              </Col>
              <Col xs="12" sm="4" lg="4" className="my-2 my-sm-0">
                {" "}
                <Input
                  type="select"
                  id="exampleCustomSelect"
                  name="customSelect"
                  value={Count}
                  onChange={(e) => {
                    setCount(e.target.value);
                  }}
                >
                  <option value={5}>Show Entries</option>
                  <option value={10}>10</option>
                  <option value={10}>20</option>
                  <option value={50}>50</option>
                  <option value={undefined}>All</option>
                </Input>
              </Col>
              <Col xs="12" sm="4" lg="3" className="">
                {user?.type === "kitchen-admin" ? (
                  <>
                    {catalogPermissions?.add ? (
                      <Button color="primary" onClick={addToggle} block>
                        Add
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <Button color="primary" onClick={addToggle} block>
                    Add
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          {user?.type === "kitchen-admin" ? (
            <>
              {catalogPermissions?.get ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Categories</th>
                      <th scope="col">Start time</th>
                      <th scope="col">End time</th>
                      <th scope="col">Availability</th>
                      <th scope="col">Availability Date</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {catalogs.map((el, idx) => {
                      return (
                        <tr key={idx}>
                          <th scope="row">{el.category}</th>
                          <td>{returnDate(el?.startTime)}</td>
                          <td>{returnDate(el?.endTime)}</td>
                          <td> {el?.availability}</td>
                          <td>
                            {el?.availabilityDate
                              ? `${moment
                                .unix(el?.availabilityDate?.startDate.seconds)
                                .format("DD-MMM-YYYY")} to 
                              ${moment
                                .unix(el?.availabilityDate?.endDate.seconds)
                                .format("DD-MMM-YYYY")}
												  `
                              : ""}
                          </td>
                          <td>
                            {catalogPermissions?.edit && (
                              <Button
                                className="btn-sm"
                                color="primary"
                                onClick={() => {
                                  setEditData(el);
                                  setId(el?.id);
                                  editToggle();
                                }}
                              >
                                Edit
                              </Button>
                            )}
                            {catalogPermissions?.delete && (
                              <Button
                                className="btn-sm"
                                color="danger"
                                onClick={() => {
                                  setId(el?.id);
                                  deleteToggle();
                                }}
                              >
                                Delete
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <Row>You don't have the permsions to access the page</Row>
              )}
            </>
          ) : (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Categories</th>
                  <th scope="col">Start time</th>
                  <th scope="col">End time</th>
                  <th scope="col">Availability</th>
                  <th scope="col">Availability Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {catalogs.map((el, idx) => {
                  return (
                    <tr key={idx}>
                      <th scope="row">{el.category}</th>
                      <td>{returnDate(el?.startTime)}</td>
                      <td>{returnDate(el?.endTime)}</td>
                      <td> {el?.availability}</td>
                      <td>
                        {el?.availabilityDate
                          ? `${moment
                            .unix(el?.availabilityDate?.startDate.seconds)
                            .format("DD-MMM-YYYY")} to 
                              ${moment
                            .unix(el?.availabilityDate?.endDate.seconds)
                            .format("DD-MMM-YYYY")}
												  `
                          : ""}
                      </td>
                      <td>
                        <Button
                          className="btn-sm"
                          color="primary"
                          onClick={() => {
                            setEditData(el);
                            setId(el?.id);
                            editToggle();
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          className="btn-sm"
                          color="danger"
                          onClick={() => {
                            setId(el?.id);
                            deleteToggle();
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}


        </Card>
      </div>
    </Row>
  );
}

export default CatalogTable;
