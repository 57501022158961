import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Input,
  Col,
  Spinner,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Pagination from "components/Pagination/Pagination";
import { getSubscriptionRequests } from "store/actions/RequestActions";
import { rejectSubscriptionRequest } from "store/actions/RequestActions";
import { acceptSubscriptionRequest } from "store/actions/RequestActions";
import { downgradeSubscription } from "store/actions/RequestActions";

const EasypaisaRequests = () => {
  const dispatch = useDispatch();
  const { subscriptionRequests } = useSelector((state) => state.requests);
  const [loading, setLoading] = useState(false);

  const [hitsPerPage, setHitsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [imagePreviewModal, setImagePreviewModal] = useState(false);

  const [activeSubscription, setActiveSubscription] = useState(null);
  const [requestActionType, setRequestActionType] = useState("");
  const [requestModal, setRequestModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const previewImageToggler = (url = "") => {
    if (imagePreviewModal) {
      setPreviewImageUrl("");
    } else {
      setPreviewImageUrl(url);
    }
    setImagePreviewModal(!imagePreviewModal);
  };

  const requestModalToggler = (actionType = "", subscription = null) => {
    if (requestModal) {
      setActiveSubscription(null);
      setRequestActionType("");
    } else {
      setActiveSubscription(subscription);
      setRequestActionType(actionType);
    }
    setRequestModal(!requestModal);
  };

  const handleRequestAction = (actionType) => {
    setRequestLoading(true);
    if (
      actionType === "accept" &&
      activeSubscription?.request === "downgrade"
    ) {
      dispatch(
        downgradeSubscription({
          id: activeSubscription?.uid,
          onSuccess: () => {
            setRequestLoading(false);
            requestModalToggler();
          },
          onError: () => setRequestLoading(false),
        })
      );
    } else if (actionType === "accept") {
      dispatch(
        acceptSubscriptionRequest({
          id: activeSubscription?.uid,
          subscriptionType: activeSubscription?.subscriptionType,
          imageUrl: activeSubscription?.paymentImageUrl,
          startDate: activeSubscription?.startDate,
          onSuccess: () => {
            setRequestLoading(false);
            requestModalToggler();
          },
          onError: () => setRequestLoading(false),
        })
      );
    } else {
      dispatch(
        rejectSubscriptionRequest({
          id: activeSubscription?.uid,
          imageUrl: activeSubscription?.paymentImageUrl,
          onSuccess: () => {
            setRequestLoading(false);
            requestModalToggler();
          },
          onError: () => setRequestLoading(false),
        })
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      getSubscriptionRequests({
        onComplete: () => setLoading(false),
      })
    );
  }, []);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                <Col md="3" className="p-0">
                  <h3 className="mb-0">Requests</h3>
                </Col>
                <Col
                  md="4"
                  lg="3"
                  className="d-flex flex-column flex-sm-row p-0"
                >
                  {/* <Input
                    className="mt-3 mt-sm-0 mr-sm-2"
                    placeholder="Search here..."
                  /> */}
                  <Input
                    type="select"
                    className="mt-3 mt-sm-0 ml-sm-2"
                    value={hitsPerPage}
                    onChange={(e) => {
                      setCurrentPage(0);
                      setHitsPerPage(e.target.value);
                    }}
                  >
                    <option value="5">Show Entries</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </Input>
                </Col>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Restaurant Name</th>
                    <th scope="col">Email</th>
                    <th scope="col" className="text-center">
                      Subscription Type
                    </th>
                    <th scope="col" className="text-center">
                      Payment Photo
                    </th>
                    <th scope="col" className="text-center">
                      Request
                    </th>
                    <th scope="col">Date</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={7} className="text-center py-5">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  ) : subscriptionRequests?.length > 0 ? (
                    subscriptionRequests
                      ?.slice(
                        currentPage * hitsPerPage,
                        (currentPage + 1) * hitsPerPage
                      )
                      ?.map((subscription) => (
                        <tr key={subscription?.uid}>
                          <th scope="row" className="text-capitalize">
                            {subscription?.restaurantName}
                          </th>
                          <td>{subscription?.email}</td>
                          <td className="text-capitalize text-center">
                            <h3 className="mb-0">
                              <Badge
                                style={{ width: "80px" }}
                                color={
                                  subscription?.subscriptionType === "yearly"
                                    ? "success"
                                    : "primary"
                                }
                              >
                                {subscription?.subscriptionType}
                              </Badge>
                            </h3>
                          </td>
                          <td className="text-center">
                            {subscription?.paymentImageUrl ? (
                              <img
                                style={{ height: 65 }}
                                className="cursor-pointer object-fit-cover overflow-hidden"
                                onClick={() =>
                                  previewImageToggler(
                                    subscription?.paymentImageUrl
                                  )
                                }
                                src={subscription?.paymentImageUrl}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="text-capitalize text-center">
                            <h5
                              className={`${
                                subscription?.request === "subscription" &&
                                "bg-primary"
                              }
                              ${
                                subscription?.request === "upgrade" &&
                                "bg-success"
                              }
                              ${
                                subscription?.request === "downgrade" &&
                                "bg-warning"
                              } text-white mb-0 px-3 py-1 rounded-pill`}
                            >
                              {subscription?.request}
                            </h5>
                          </td>
                          <td>
                            {subscription?.createdAt
                              ?.toDate()
                              .toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })}
                          </td>
                          <td>
                            <Button
                              className="btn-sm"
                              color="primary"
                              onClick={() =>
                                requestModalToggler("accept", subscription)
                              }
                            >
                              Accept
                            </Button>
                            <Button
                              className="btn-sm"
                              color="danger"
                              onClick={() =>
                                requestModalToggler("reject", subscription)
                              }
                            >
                              Reject
                            </Button>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center py-5">
                        No Request
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(
                    subscriptionRequests?.length / hitsPerPage
                  )}
                  onPageChange={handlePageChange}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal size="lg" isOpen={imagePreviewModal} toggle={previewImageToggler}>
        <ModalHeader
          toggle={previewImageToggler}
          className="align-items-center border-bottom"
        >
          Preview Payment Photo
        </ModalHeader>
        <ModalBody>
          <img
            src={previewImageUrl}
            style={{ objectFit: "contain" }}
            className="w-100 h-100"
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={requestModal} toggle={requestModalToggler}>
        <ModalHeader
          toggle={requestModalToggler}
          className="align-items-center text-capitalize"
        >
          {requestActionType} Subscription Request
        </ModalHeader>
        <ModalBody>
          <h4 className="text-left">
            Are you sure you want to {requestActionType} subscription request?
          </h4>
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button
            color="primary"
            className="text-capitalize"
            style={{ width: "90px" }}
            onClick={() => handleRequestAction(requestActionType)}
            disabled={requestLoading}
          >
            {requestLoading ? <Spinner size="sm" /> : requestActionType}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EasypaisaRequests;
