const initState = {
  isLoading: false,
  driverData: [],
  drivers: [],
  driverRestData: null,
  driverAppLink: "",
};
const driverReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_ALL_DRIVERS":
      return {
        ...state,
        driverData: action.payload,
      };
    case "DRIVER_DETAILS":
      return {
        ...state,
        driverRestData: action.payload,
      };
    case "DRIVER_LOADER":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "DRIVER_DELETED":
      return {
        ...state,
        driverData: state.driverData?.filter(
          (driver) => driver.id !== action.payload
        ),
      };
    case "DRIVER_UPDATED": {
      return {
        ...state,
        driverData: state.driverData.map((driver) => {
          if (driver.id === action.payload.id) {
            return action.payload.obj;
          }
          return driver;
        }),
      };
    }
    case "DRIVER_ADDED":
      return {
        ...state,
        driverData: [...state.driverData, action.payload],
      };
    case "GET_RESTAURANT_DRIVERS":
      return {
        ...state,
        drivers: action.payload,
      };
    case "GET_DRIVER_APP":
      return {
        ...state,
        driverAppLink: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default driverReducer;
