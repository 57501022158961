import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { useDispatch, useSelector } from "react-redux";
import LandingLayout from "layouts/LandingLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import { withProfiler } from "@sentry/react";
import { reRegisterSnapshot } from "store/actions/authActions";

function App() {
  const dispatch = useDispatch();
  let { user, uid } = useSelector((state) => state.auth);

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  useEffect(() => {
    if (uid && user?.type) {
      dispatch(reRegisterSnapshot(uid, user?.type));
    }
  }, [uid, user?.type]);

  return (
    <BrowserRouter>
      {uid ? (
        <Switch>
          {user?.subScriptionStatus !== "subscribe" &&
          user?.type !== "admin" ? (
            <>
              <Route
                path="/admin"
                render={(props) => <AdminLayout {...props} />}
              />
              <Redirect from="/" to="/admin/subscriptions" />
            </>
          ) : (
            <>
              <Route
                path="/admin"
                render={(props) => <AdminLayout {...props} />}
              />
              <Redirect from="/" to="/admin" />
            </>
          )}
        </Switch>
      ) : (
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/" render={(props) => <LandingLayout {...props} />} />
        </Switch>
      )}
    </BrowserRouter>
  );
}

export default withProfiler(App);
