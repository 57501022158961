import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { RepositoryFactory } from "repository/RepositoryFactory";
let User = RepositoryFactory.get("user");
// Import the "Loader" action if not already done

export const addNewUser =
  (payload, permissions, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(Loader(true));
      const { data } = await User.add(payload);
      if (data.success) {
        if (Object.keys(permissions).length > 0) {
          await firebase
            .firestore()
            .collection("permissions")
            .add({
              userID: data.data.id,
              ...permissions,
            });
        }
        onSuccess();
        payload.type === "kitchen-cook"
          ? dispatch({
              type: "STAFF_ADDED",
              payload: data.data,
            })
          : dispatch({
              type: "DRIVER_ADDED",
              payload: data.data,
            });

        dispatch(Loader(false));
      } else {
        console.log("errr", data);
        alert(data.message);
        dispatch(Loader(false));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(Loader(false));
    }
  };

export const login = (creds) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN_REQUEST",
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then(async (data) => {
        var user = data.user;
        const UID = data.user.uid;
        if (user.emailVerified || creds.email === "testadmin@gmail.com") {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((res) => {
              console.log(UID, res?.claims?.role, "res?.claims?.role");
              dispatch(reRegisterSnapshot(UID, res?.claims?.role));
            });
        } else {
          throw new Error("Email not Verified");
        }
      })
      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
          uid: "",
          error: error,
        });
        dispatch({
          type: "LOGIN_REQUEST_END",
        });
        dispatch(logout());
        if (error.message.includes("INVALID_LOGIN_CREDENTIALS")) {
          toast.warning("Invalid Credentials. Please try again");
        } else {
          toast.warning(error.message || "An unknown error has occured");
        }
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then((data) => {
        dispatch({
          type: "LOGOUT",
        });
        localStorage.clear();
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
          uid: "",
          error: error,
        });
      });
  };
};

export const forgetPassword =
  (email, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(forgetLoader(true));
    try {
      await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then((res) => {
          dispatch(forgetLoader(false));
          toast.success("Please check your email and reset the password");
          onSuccess();
        })
        .catch((err) => {
          dispatch(forgetLoader(false));
          toast.error(err.message);
        });
    } catch (error) {
      dispatch(forgetLoader(false));
      toast.error(error.message);
    }
  };

export const reRegisterSnapshot = (id, role) => async (dispatch) => {
  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .onSnapshot(async (doc) => {
      let user = doc.data();
      let isRoleValid =
        role == "kitchen-cook" ||
        role == "kitchen-admin" ||
        role == "restaurant" ||
        role == "admin" ||
        role == "sub-admin";

      if (isRoleValid) {
        if (role == "kitchen-cook" || role == "kitchen-admin") {
          dispatch(getPermissions(doc.id));
        }
        if (role == "restaurant") {
          if (user?.subscription?.subscriptionID) {
            await User.getSubscriptionStatus(user?.subscription?.subscriptionID)
              .then((res) => {
                console.log(res, "<<<getSubscriptionStatus");
              })
              .catch((ex) => {
                toast.error(ex.response.data.error);
              });
          }
        }
        dispatch({
          type: "LOGIN_SUCCESS",
          user: { id: doc.id, ...doc.data() },
          error: "",
        });
        dispatch({
          type: "LOGIN_REQUEST_END",
        });
      } else {
        toast.error("You are not permitted to access the platform");
        dispatch(logout());
      }
    });
};

export const getPermissions = (id) => async (dispatach) => {
  let permissionsData = {};
  firebase
    .firestore()
    .collection("permissions")
    .where("userID", "==", id)
    .limit(1)
    .get()
    .then(async (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        permissionsData = { id: doc.id, ...doc.data() };
      });
      await dispatach({
        type: "PERMISSIONS",
        payload: permissionsData,
      });
    })
    .catch((error) => {
      console.error("Error getting documents: ", error);
    });
};

export const updateColors = (id, payload) => async (dispatch) => {
  await firebase
    .firestore()
    .collection("users")
    .doc(id)
    .update({ ...payload })
    .then(toast.success("Restaurant colors updated"));
};

const Loader = (data) => async (dispatch) => {
  dispatch({
    type: "CATALOG_LOADER",
    payload: data,
  });
};

const forgetLoader = (data) => async (dispatch) => {
  dispatch({
    type: "FORGET_LOADER",
    payload: data,
  });
};
export const adminSignUp =
  (payload, password, onSuccess = (data) => {}) =>
  async (dispatch) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, password)
      .then((data) => {
        firebase
          .firestore()
          .collection("users")
          .doc(data.user?.uid)
          .set({
            name: payload?.name,
            email: payload?.email,
            firsttimelogin: true,
            restaurantID: data?.user?.uid,
            isDeleted: false,
            status: "active",
            subScriptionStatus:
              payload?.paymentMethod === "easypaisa"
                ? "unsubscribe"
                : "subscribe",
            currency: "$",
            language: "en",
            type: "restaurant",
            restaurantLogo: "",
            socialProfiles: [
              {
                type: "facebook",
              },
              {
                type: "instagram",
              },
              {
                type: "tiktok",
              },
              {
                type: "discord",
              },
              {
                type: "youtube",
              },
              {
                type: "website",
              },
              {
                type: "linktree",
              },
              {
                type: "linkedIn",
              },
              {
                type: "snapchat",
              },
              {
                type: "twiter",
              },
            ],
            storeTiming: [
              { day: "Monday", startTime: "", endTime: "" },
              { day: "Tuesday", startTime: "", endTime: "" },
              { day: "Wednesday", startTime: "", endTime: "" },
              { day: "Thursday", startTime: "", endTime: "" },
              { day: "Friday", startTime: "", endTime: "" },
              { day: "Saturday", startTime: "", endTime: "" },
              { day: "Sunday", startTime: "", endTime: "" },
            ],
            createdAt: firebase.firestore.Timestamp.now(),
          })
          .then((res) => {
            console.log(data, "adminsignup");
            onSuccess(data);
          })
          .catch((err) => {
            // dispatch(signupLoader(false));
            toast.error(err.message);
          });
      })
      .catch((err) => {
        // dispatch(signupLoader(false));
        toast.error(err.message);
      });
  };
export const CloseAlert = (id, onDismiss) => async (dispatch) => {
  console.log(id, "id>>>>>");
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(id)
      .update({
        firsttimelogin: false,
      })
      .then(() => onDismiss()); // Call onSuccess callback upon success
  } catch (error) {
    console.error("Error closing alert:", error);
  }
};

export const easypaisaSubscription =
  ({
    userData,
    paymentImage,
    request,
    startDate = "",
    onSuccess = () => {},
    onError = () => {},
  }) =>
  async () => {
    try {
      let toastMessage = "Subscription request";
      if (request != "subscription") {
        toastMessage = `${
          request.charAt(0).toUpperCase() + request.slice(1)
        } subscription request`;
      }

      const docRef = firebase
        .firestore()
        .collection("subscriptions")
        .doc(userData?.uid);
      const docSnapshot = await docRef.get();

      if (docSnapshot.exists) {
        throw new Error(`${toastMessage} already sent.`);
      }

      let downloadURL = "";
      if (paymentImage) {
        const storageRef = firebase
          .storage()
          .ref(`subscriptionImages/${userData?.uid}/${paymentImage?.name}`);
        const uploadTaskSnapshot = await storageRef.put(paymentImage);
        downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
      }

      await firebase
        .firestore()
        .collection("subscriptions")
        .doc(userData?.uid)
        .set({
          ...userData,
          request,
          startDate: startDate,
          status: "pending",
          paymentImageUrl: downloadURL,
          createdAt: firebase.firestore.Timestamp.now(),
        });

      toast.success(`${toastMessage} sent successfully.`);
      onSuccess();
    } catch (error) {
      console.error(error.message || "An unknown error has occurred.");
      toast.error(error.message || "An unknown error has occurred.");
      onError();
    }
  };

export const checkAuthAndSendVerification = ({ onSuccess = () => {} }) => {
  return async () => {
    try {
      const user = firebase.auth().currentUser;

      if (user && !user.emailVerified) {
        await user.sendEmailVerification();
        toast.success("Verification Email has been sent");
        onSuccess();
      }
    } catch (error) {
      toast.error(`Error sending verification email: ${error.message}`);
    }
  };
};
