import {
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Input,
  Label,
  FormGroup,
  Form,
  Spinner,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { assaignedDriverToOrder } from "store/actions/orderActions";
import { getRestaurantDrivers } from "store/actions/driverAction";
function AssignDriver({
  openModal,
  assignToggle,
  selectedOrderId,
  setNeedsRefetch,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { drivers, isLoading } = useSelector((state) => state.driver);
  const [driver, setDriver] = useState("");
  const [driversList, setDriversList] = useState([]);
  useEffect(() => {
    setDriversList(drivers);
  }, [drivers]);
  const handleSave = (e) => {
    e.preventDefault();
    const payload = {
      orderId: selectedOrderId,
      driverId: driver,
    };
    dispatch(
      assaignedDriverToOrder(payload, () => {
        assignToggle();
        setNeedsRefetch((prevState) => !prevState);
        dispatch(getRestaurantDrivers(user?.restaurantID));
      })
    );
  };
  return (
    <>
      <Modal isOpen={openModal} toggle={assignToggle}>
        <ModalHeader toggle={assignToggle}>
          Assign Deriver to Deliver Order
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => handleSave(e)}>
            <FormGroup>
              <Label for="exampleSelect">Driver</Label>
              <Input
                id="exampleSelect"
                name="role"
                type="select"
                className="text-capitalize"
                onChange={(e) => setDriver(e.target.value)}
              >
                <option value="">Select Driver</option>
                {driversList?.map((driver, index) => {
                  return (
                    <option
                      key={index}
                      value={driver?.id}
                      className="text-capitalize"
                    >
                      {driver?.name || ""}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>

            <div className="d-flex justify-content-end">
              <Button disabled={isLoading} color="primary">
                {isLoading ? <Spinner size="sm" /> : "Save"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AssignDriver;
